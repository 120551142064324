import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dashboard-main-sec">
      <div
        style={{
          width: isOpen ? "220px" : "58px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div
            // style={{ marginLeft: isOpen ? "50px" : "0px", cursor: "pointer" }}
            className="bars"
          >
            {isOpen ? (
              <i className="fas fa-arrow-circle-left" onClick={toggle}></i>
            ) : (
              <i className="fas fa-arrow-circle-right" onClick={toggle}></i>
            )}
          </div>
        </div>
        <div className="sidebar-scroll">
          <NavLink to="/user/home" className="link" activeclassname="active">
            <div className="icon">
              <img src="/assets/images/dashboard.png" alt="dashboard-logo" />
            </div>
            <div
              style={{
                // display: isOpen ? "block" : "none",
                fontFamily: "Segoe UI",
              }}
              className="link_text"
            >
              Dashboard
            </div>
          </NavLink>
          <NavLink
            to="/user/customers"
            className="link"
            activeclassname="active"
          >
            <div className="icon">
              <img src="/assets/images/customers.png" alt="customer-logo" />
            </div>
            <div
              style={{
                // display: isOpen ? "block" : "none",
                fontFamily: "Segoe UI",
              }}
              className="link_text"
            >
              Customers
            </div>
          </NavLink>
          <NavLink
            to="/user/scheduler"
            className="link"
            activeclassname="active"
          >
            <div className="icon">
              <img src="/assets/images/scheduler.png" alt="notification-logo" />
            </div>
            <div
              style={{
                // display: isOpen ? "block" : "none",
                fontFamily: "Segoe UI",
              }}
              className="link_text"
            >
              Scheduler
            </div>
          </NavLink>
        </div>
      </div>
      <main className={`main-section ${isOpen ? "main-section-open" : ""}`}>
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
