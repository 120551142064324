import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import InputField from "../elements/inputs/InputField";
import Select, { components } from "react-select";
import countryCode from "../../utils/data/CountryCodes.json";

import {
  usePostAddUserByAdminMutation,
  usePostAllOrganizationsDataForAdminMutation,
} from "../../services/apiService";
import Spinner from "../elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { addUserByAdminSchema } from "../../utils/validationSchema/validation";

const MySwal = withReactContent(Swal);

const colourStylesCountry = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    minHeight: "auto",
    minWidth: "39px",
    width: "40px",
    padding: "0px",
    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.35rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const AddUserByAdminModal = ({ closeAddUserModal, onUserAdded }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [icon, setIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );

  const [postAddUserByAdmin, { isFetching, isLoading }] =
    usePostAddUserByAdminMutation();

  const [
    postAllOrganizationsDataForAdmin,
    {
      data: organizationList,
      isLoading: isOrganizationDataLoading,
      isFetching: isOrganizationDataFetching,
    },
  ] = usePostAllOrganizationsDataForAdminMutation();

  useEffect(() => {
    postAllOrganizationsDataForAdmin({
      query: "",
      payload: {
        page: "1",
        page_size: "10",
        search: "",
        type: "all",
      },
    });
  }, []);

  const _changeIcon = () =>
    icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
      ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"),
        setPasswordType("text"))
      : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"),
        setPasswordType("password"));

  const handleAddUser = (values, resetForm) => {
    let payload = {
      f_name: values?.f_name,
      m_name: values?.m_name,
      l_name: values?.l_name,
      email: values?.email,
      mobile: `${values?.country_code}-${values?.mobile}` || "",
      password: values?.password,
      org_id: values?.org_id,
      role: 1,
      description: values?.description,
    };

    postAddUserByAdmin({ query: "", payload: payload })
      .then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: "User Details Added successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            resetForm();
            onUserAdded();
            closeAddUserModal();
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="modal-dialog  modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addUserModal">
            Add User Details
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeAddUserModal}
          />
        </div>
        <div className="modal-body right-side">
          <Formik
            initialValues={{
              f_name: "",
              m_name: "",
              l_name: "",
              email: "",
              country_code: "+1",
              mobile: "",
              password: "",
              description: "",
              org_id: null,
            }}
            onSubmit={(values, { resetForm }) =>
              handleAddUser(values, resetForm)
            }
            validationSchema={addUserByAdminSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row mb-0">
                  <div className="col-lg-4 col-md-4">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-user" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="text"
                          name="f_name"
                          label={"First Name"}
                          value={values.f_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors?.f_name && touched?.f_name ? (
                        <div className="error-message">{errors?.f_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-user" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={"did-floating-label"}
                          type="text"
                          name="m_name"
                          label={"Middle Name"}
                          value={values?.m_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors?.m_name && touched?.m_name ? (
                        <div className="error-message">{errors?.m_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-user" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="text"
                          name="l_name"
                          label={"Last Name"}
                          value={values.l_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors?.l_name && touched?.l_name ? (
                        <div className="error-message">{errors?.l_name}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-envelope" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="email"
                          name="email"
                          label={"Email"}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors?.email && touched?.email ? (
                        <div className="error-message">{errors?.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className="input-group d-flex align-items-center flex-nowrap">
                        <span
                          className="input-group-text p-0"
                          style={{ width: "auto" }}
                          id="basic-addon1"
                        >
                          <Select
                            onChange={(e) =>
                              setFieldValue("country_code", e.label)
                            }
                            placeholder={""}
                            readOnly={true}
                            styles={colourStylesCountry}
                            isSearchable={true}
                            options={countryCode}
                            defaultValue={{
                              label: "+1",
                              value: "CA",
                            }}
                            components={{
                              Control,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={"did-floating-label"}
                          type="text"
                          name="mobile"
                          label={"Contact Number"}
                          value={values.mobile
                            .replace(/[^\d]/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                          maxLength={12}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {(errors.mobile && touched.mobile) ||
                      (errors?.country_code && touched.country_code) ? (
                        <div className="error-message">
                          {errors.mobile || errors?.country_code}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-info-circle" aria-hidden="true" />{" "}
                      </span>
                      <InputField
                        className={"did-floating-input"}
                        type="text"
                        name="description"
                        label={"Description"}
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="fa fa-lock" aria-hidden="true" />
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={"did-floating-label"}
                          type={passwordType}
                          name="password"
                          label={"Enter Password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          toggle="#password-field"
                          className={icon}
                          onClick={() => _changeIcon()}
                        ></span>
                      </div>
                      {errors.password && touched.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                      <Select
                        getOptionLabel={(option) => `${option.org_name}`}
                        getOptionValue={(option) => option.org_id}
                        options={organizationList?.organizations || []}
                        styles={colourStyles}
                        placeholder="Select Organization"
                        value={organizationList?.organizations.find(
                          (option) => option.org_id === values.org_id
                        )}
                        isRequired={true}
                        label={"Select Organization"}
                        onChange={(option) =>
                          setFieldValue("org_id", option.org_id)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.org_id && touched.org_id ? (
                        <div className="error-message">{errors.org_id}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="d-flex pb-1">
                  <span>
                    <label className="label check-terms">
                      {" "}
                      <input
                        type="checkbox"
                        required
                        style={{ marginTop: "0px" }}
                        className="form-check-input"
                        id="remember_me"
                        name="remember_me"
                        value="remember_me"
                      />{" "}
                      I agree to Data-Fusion
                      <a
                        href="https://localhost:3000/terms-and-conditions"
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          color: "#50B5FF",
                        }}
                      >
                        T&C
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://localhost:3000/privacy-policy"
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          color: "#50B5FF",
                        }}
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </span>
                </div>

                <div className="pt-4 d-flex justify-content-center">
                  <button className="btn btn-primary w-50 p-2" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Spinner
        isLoading={isFetching || isLoading || isOrganizationDataFetching}
      />
    </div>
  );
};

export default AddUserByAdminModal;
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
