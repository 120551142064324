import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  setScheduleList,
  setPage,
  setPageSize,
  setSearch,
} from "../../redux/slice/schedulerSlice";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import { usePostAllScheduledJobsDataMutation } from "../../services/apiService";

import Spinner from "../../components/elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Breadcrumb from "../../components/elements/Breadcrumb/Breadcrumb";
import AddScheduleModal from "../../components/AddSchedule/AddScheduleModal";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import UpdateScheduleModal from "../../components/UpdateScheduleModal/UpdateScheduleModal";
import UserLayout from "../../components/User/UserLayout";
const MySwal = withReactContent(Swal);
const $ = window.$;

const MasterUserScheduler = () => {
  const dispatch = useDispatch();
  const [postAllScheduledJobsData, { isLoading, isFetching }] =
    usePostAllScheduledJobsDataMutation();
  const { scheduledList, page, page_size, search, total_pages } = useSelector(
    (state) => state.scheduler
  );

  const encryptedOrg = localStorage.getItem("org_data");
  const decryptedOrg = CryptoJS.AES.decrypt(
    encryptedOrg,
    process.env.REACT_APP_CRYPTOJS_SECRETKEY,
    {
      iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
    }
  ).toString(CryptoJS.enc.Utf8);
  const orgData = JSON.parse(decryptedOrg);

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const [paginationLength, setPaginationLength] = useState(page_size);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [showUpdateScheduleModal, setShowUpdateScheduleModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const breadcrumbItems = [{ label: "Schedule Management" }];

  const fetchSchedules = async () => {
    const payload = {
      page,
      page_size,
      search,
      user_id: userData?.user?.id,
      org_id: orgData?.master_org?.org_id,
    };
    const response = await postAllScheduledJobsData({
      query: "",
      payload: payload,
    });

    if (response?.data) {
      console.log("response", response?.data);
      dispatch(setScheduleList(response.data));
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [page, page_size, search]);

  const handlePaginationLengthChange = (newPageSize) => {
    setPaginationLength(newPageSize);
    dispatch(setPageSize(newPageSize));
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();

    const renderPagination = () => {
      const table = $("#scheduledListTable").DataTable();
      const totalPages = total_pages;
      const currentPage = page;

      const paginationContainer = $(".dataTables_paginate");
      const paginationList = $("<ul>", { class: "pagination" });
      paginationContainer.empty().append(paginationList);

      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (totalPages > 5 && currentPage <= 3) {
        endPage = 5;
      } else if (totalPages > 5 && currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
      const firstButton = $("<a>", {
        text: "First",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(1));
            table.page("first").draw(false);
          }
        },
      });

      const previousButton = $("<a>", {
        text: "Previous",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(page - 1));
            table.page("previous").draw(false);
          }
        },
      });

      const nextButton = $("<a>", {
        text: "Next",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(page + 1));
            table.page("next").draw(false);
          }
        },
      });
      const lastButton = $("<a>", {
        text: "Last",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(totalPages));
            table.page("last").draw(false);
          }
        },
      });

      const firstListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(firstButton);
      const previousListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(previousButton);
      const nextListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(nextButton);
      const lastListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(lastButton);
      paginationList.append(firstListItem);
      paginationList.append(previousListItem);

      for (let i = startPage; i <= endPage; i++) {
        const pageLink = $("<a>", {
          text: i,
          class: `page-link pointer ${i === currentPage ? "active" : ""}`,
          click: function () {
            dispatch(setPage(i));
            table.page(i - 1).draw(false);
          },
        });

        const listItem = $("<li>", {
          class: `page-item ${i === currentPage ? "active" : ""}`,
        }).append(pageLink);
        paginationList.append(listItem);
      }

      paginationList.append(nextListItem);
      paginationList.append(lastListItem);
    };

    const table = $("#scheduledListTable").DataTable({
      searching: true,
      order: [],
      data: scheduledList || [],
      columns: [
        {
          title: "Clinic Name",
          data: "relational_org_ehr_system_clinic.clinic_name",
        },
        { title: "API Name", data: "api_name" },
        { title: "API URL", data: "api_url" },

        {
          title: "Recurrence Pattern",
          data: null,
          render: (data, type, row) => {
            switch (row.recurrence_pattern) {
              case "daily":
                return `Every ${row.recurrence_day_interval} day(s)`;
              case "weekly":
                return `Weekly on: ${JSON.parse(
                  row.recurrence_week_day || "[]"
                ).join(", ")}`;
              case "monthly":
                if (row.recurrence_monthly_type === "day") {
                  return `Monthly on day ${row.recurrence_monthly_day}`;
                } else if (row.recurrence_monthly_type === "week_day") {
                  return `Monthly on the ${row.recurrence_monthly_week} ${row.recurrence_monthly_week_day}`;
                }
                return "Monthly";
              case "yearly":
                if (row.recurrence_yearly_type === "day") {
                  return `Yearly on day ${row.recurrence_yearly_day} of ${row.recurrence_yearly_month}`;
                } else if (row.recurrence_yearly_type === "week_day") {
                  return `Yearly on the ${row.recurrence_yearly_week} ${row.recurrence_yearly_week_day} of ${row.recurrence_yearly_month}`;
                }
                return "Yearly";
              default:
                return "N/A";
            }
          },
        },

        {
          title: "Recurrence End Type",
          data: null,
          render: (data, type, row) => {
            switch (row.recurrence_end_type) {
              case "after":
                return `Ends after ${row.recurrence_end_after_occurrences} occurrence(s)`;
              case "by":
                return `Ends by ${moment(row.recurrence_end_by_date).format(
                  "DD/MM/YYYY"
                )}`;
              case "no_end":
                return "No end date";
              default:
                return "N/A";
            }
          },
        },

        {
          title: "Actions",
          data: null,
          render: (data, type, row) => {
            return `
            <div style="display:flex;gap:6px">
              <button class="edit-button" data-user='${JSON.stringify(
                row
              )}'>Edit</button>
            
              </div>
              `;
          },
        },
      ],
      drawCallback: function () {
        renderPagination();
      },
    });

    table.page.len(paginationLength).draw();
    $("#scheduledListTable").on("length.dt", function (e, settings, len) {
      handlePaginationLengthChange(len);
    });

    $("#scheduledListTable").on("click", ".edit-button", function () {
      const user = $(this).data("user");
      setSelectedSchedule(user);
      setShowUpdateScheduleModal(true);
    });

    $("#global-search").on("keyup", function () {
      dispatch(setSearch(this.value));
      table.search(this.value).draw();
    });

    $("#page-size").on("change", function () {
      const newSize = $(this).val();
      dispatch(setPageSize(parseInt(newSize)));
      table.page.len(parseInt(newSize)).draw();
    });

    return () => {
      $("#scheduledListTable").off("length.dt");
      table.destroy();
    };
  }, [scheduledList, page, page_size, total_pages]);

  const openAddScheduleModal = () => {
    setShowAddScheduleModal(true);
  };

  const closeAddScheduleModal = () => {
    setShowAddScheduleModal(false);
  };

  const closeUpdateScheduleModal = () => {
    setShowUpdateScheduleModal(false);
  };

  return (
    <UserLayout>
      <Breadcrumb items={breadcrumbItems} />
      <div className="overview-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="configure-head mt-0 mb-4">
                <div className="same-heading-icon">
                  <h4>Scheduled List</h4>
                </div>
              </div>
              <div className="date-section">
                <div className="d-flex flex-wrap gap-3">
                  <div className="select-field">
                    <select
                      id="page-size"
                      defaultValue={page_size}
                      onChange={(e) => {
                        handlePaginationLengthChange(e.target.value);
                      }}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="search-field">
                    <div className="input-group">
                      <input
                        type="text"
                        id="global-search"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-success" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="common-same-button"
                    type="button"
                    onClick={openAddScheduleModal}
                  >
                    Add New Schedule
                  </button>
                </div>
              </div>
              <div className="white-box">
                <div className="common-table m-0">
                  <table
                    className="table dt-responsive table-hover nowrap table-striped list-table"
                    style={{ width: "100%" }}
                    id="scheduledListTable"
                  ></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddScheduleModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <AddScheduleModal
            closeAddScheduleModal={closeAddScheduleModal}
            onScheduleAdded={() => {
              fetchSchedules();
            }}
          />
        </div>
      )}
      {showUpdateScheduleModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <UpdateScheduleModal
            schedule={selectedSchedule}
            closeUpdateScheduleModal={closeUpdateScheduleModal}
            onScheduleUpdate={() => {
              fetchSchedules();
            }}
          />
        </div>
      )}
      <Spinner isLoading={isFetching || isLoading} />
    </UserLayout>
  );
};

export default MasterUserScheduler;
