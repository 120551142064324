import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import InputField from "../elements/inputs/InputField";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TransferList from "../elements/transferList/TransferList";

import { useNavigate, useParams } from "react-router-dom";

import {
  useGetEHRScopesQuery,
  useGetEHRSystemsQuery,
  usePutCustomerUpdateMutation,
} from "../../services/apiService";
import { updateCustomerSchema } from "../../utils/validationSchema/validation";
import Spinner from "../elements/Spinner/Spinner";

const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const UpdateCustomerModal = ({
  closeUpdateCustomerModal,
  customer,
  onCustomerUpdated,
}) => {
  const [ehrProvider, setEHRProvider] = useState(customer.ehr_id || "");
  const { data: ehrOptions } = useGetEHRSystemsQuery();
  const [initialValues, setInitialValues] = useState({
    ehr_id: customer?.ehr_id || "",
    clinic_scope: JSON.parse(customer?.clinic_scope) || [],
    clinic_name: customer?.clinic_name || "",
    clinic_email: customer?.clinic_email || "",
    clinic_address: customer?.clinic_address || "",
    environment:
      customer?.secret_details?.[0]?.environment || "default_sandbox",
    client_id: customer?.secret_details?.[0]?.client_id || "",
    client_secret: customer?.secret_details?.[0]?.client_secret || "",
    practice_id: customer?.secret_details?.[0]?.practice_id,
    redirect_url: customer?.redirect_url,

    private_key: customer?.secret_details?.[0]?.private_key,
  });
  const [isScopesLoading, setIsScopesLoading] = useState(false);
  const navigate = useNavigate();
  const [putCustomerUpdate, { isLoading, isFetching }] =
    usePutCustomerUpdateMutation();

  const { data: scopeOptions, isLoading: isScopeLoading } =
    useGetEHRScopesQuery(ehrProvider, {
      skip: !ehrProvider,
      onSettled: () => setIsScopesLoading(false),
      onLoading: () => setIsScopesLoading(true),
    });

  const params = useParams();

  const onSubmit = (values, resetForm, setErrors) => {
    // Custom validation for client_id and client_secret based on environment
    const errors = {};

    if (
      values.environment === "user_sandbox" ||
      values.environment === "production"
    ) {
      if (!values.client_id) {
        errors.client_id = "Client ID is required";
      }
      if (values.ehr_id !== 5 && !values.client_secret) {
        errors.client_secret = "Client Secret is required";
      }
      if (values.ehr_id === 5) {
        if (!values.private_key) {
          errors.private_key = "Private Key is required";
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return; // Prevent form submission if there are validation errors
    }

    const payload = {
      id: customer?.id,
      ehr_id: values.ehr_id,
      org_id: params?.orgId,
      clinic_name: values.clinic_name,
      clinic_email: values.clinic_email,
      clinic_scope: JSON.stringify(values.clinic_scope),
      clinic_address: values.clinic_address,
      client_id: values.client_id,
      client_secret: values.client_secret,
      environment: values.environment,
      redirect_url: values.redirect_url,
      ...(values.ehr_id === 5 && {
        private_key: values.private_key,
      }),
      ...(ehrProvider === 12 && { practice_id: values.practice_id }),
    };
    putCustomerUpdate({ id: customer.id, payload })
      .then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: "Customer Details Updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            resetForm();
            onCustomerUpdated();
            closeUpdateCustomerModal();
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEHRProviderChange = (selectedOption, setFieldValue) => {
    setEHRProvider(selectedOption ? selectedOption.ehr_id : "");
    setFieldValue("ehr_id", selectedOption.ehr_id);
  };

  const handleEnvironmentChange = (e, setFieldValue) => {
    setFieldValue("environment", e.target.value);
  };
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="updateCustomerModal">
            Update Customer Details
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeUpdateCustomerModal}
          />
        </div>
        <div className="modal-body right-side">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { resetForm, setErrors }) => {
              onSubmit(values, resetForm, setErrors);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={updateCustomerSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <h4 className="login-heading mb-3">EHR Details</h4>
                <div className="row mb-1">
                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                      <Select
                        getOptionLabel={(option) => `${option.ehr_name}`}
                        getOptionValue={(option) => option.ehr_id}
                        options={ehrOptions?.ehr_systems || []}
                        styles={colourStyles}
                        placeholder="Select EHR provider"
                        value={ehrOptions?.ehr_systems?.find(
                          (option) => option.ehr_id === values.ehr_id
                        )}
                        isRequired={true}
                        label={"Select EHR Provider"}
                        onChange={(option) =>
                          handleEHRProviderChange(option, setFieldValue)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.ehr_id && touched.ehr_id ? (
                        <div className="error-message">{errors.ehr_id}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-0">
                  <h4 className="login-heading mb-3">Environment Details</h4>
                  <div className="row form-check-customer">
                    <div className="col-md-4 col-sm-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="environment"
                          id="default_sandbox"
                          value="default_sandbox"
                          checked={values.environment === "default_sandbox"}
                          onChange={(e) =>
                            handleEnvironmentChange(e, setFieldValue)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="default_sandbox"
                        >
                          Default Sandbox
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="environment"
                          id="user_sandbox"
                          value="user_sandbox"
                          checked={values.environment === "user_sandbox"}
                          onChange={(e) =>
                            handleEnvironmentChange(e, setFieldValue)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="user_sandbox"
                        >
                          User Sandbox
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="environment"
                          id="production"
                          value="production"
                          checked={values.environment === "production"}
                          onChange={(e) =>
                            handleEnvironmentChange(e, setFieldValue)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="production"
                        >
                          Production
                        </label>
                      </div>
                    </div>
                  </div>

                  {values.environment === "user_sandbox" ||
                  values.environment === "production" ? (
                    <>
                      <div className="row mb-0">
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="fa fa-key" aria-hidden="true" />{" "}
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="client_id"
                                  label={"Client ID"}
                                  value={values.client_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.client_id && touched?.client_id ? (
                                <div className="error-message">
                                  {errors?.client_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  />{" "}
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    ehrProvider === 12
                                      ? "did-floating-label did-floating-labe-astrisk"
                                      : "did-floating-label"
                                  }
                                  type="text"
                                  name="client_secret"
                                  label={"Client Secret"}
                                  value={values.client_secret}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.client_secret &&
                              touched?.client_secret ? (
                                <div className="error-message">
                                  {errors?.client_secret}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      {ehrProvider === 5 && (
                        <div className="row mb-0">
                          {/* <div className="col-lg-6 col-md-6">
                            <div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-key"
                                      aria-hidden="true"
                                    />{" "}
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type="text"
                                    name="public_key"
                                    label={"Public Key"}
                                    value={values.public_key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                {errors?.public_key && touched?.public_key ? (
                                  <div className="error-message">
                                    {errors?.public_key}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div> */}
                          {ehrProvider === 12 && (
                            <div className="row mb-0">
                              <div className="col-lg-12 col-md-12">
                                <div className="mb-3 input-group">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-id-card"
                                      aria-hidden="true"
                                    />{" "}
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    type="text"
                                    name="practice_id"
                                    label={"Practice Id"}
                                    value={values.practice_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-lg-12 col-md-12">
                            <div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-key"
                                      aria-hidden="true"
                                    />{" "}
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type="text"
                                    name="private_key"
                                    label={"Private Key"}
                                    value={values.private_key}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                {errors?.private_key && touched?.private_key ? (
                                  <div className="error-message">
                                    {errors?.private_key}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div
                              style={{
                                color: "#ff5f15",
                                marginTop: "0px",
                                fontWeight: "normal",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Please upload your public key into this epic
                              application. For more details click on this link :{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://fhir.epic.com/Documentation?docId=epiconfhirrequestprocessstepbystep"
                              >
                                Epic Documentation
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <h4 className="login-heading mb-3">Clinic Scope</h4>
                      <TransferList
                        transferItems={scopeOptions?.ehr_scopes || []}
                        selectedItems={values.clinic_scope}
                        isSelectedListDisabled={true}
                        itemSelector={(selectedItems) =>
                          setFieldValue("clinic_scope", selectedItems)
                        }
                        ListRow={({ ehr_scope_name, resource }) => (
                          <div>{`${resource} - ${ehr_scope_name}`}</div>
                        )}
                      />

                      {errors.clinic_scope && touched.clinic_scope ? (
                        <div className="error-message">
                          {errors.clinic_scope}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <h4 className="login-heading mb-3">Clinic Details</h4>
                <div className="row mb-0">
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="clinic_name"
                            label={"Clinic Name"}
                            value={values.clinic_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.clinic_name && touched?.clinic_name ? (
                          <div className="error-message">
                            {errors?.clinic_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-envelope" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="email"
                            name="clinic_email"
                            label={"Clinic Email"}
                            value={values.clinic_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.clinic_email && touched?.clinic_email ? (
                          <div className="error-message">
                            {errors?.clinic_email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-link" aria-hidden="true" />{" "}
                      </span>
                      <InputField
                        className={"did-floating-input"}
                        type="text"
                        name="redirect_url"
                        label={"Redirect Url"}
                        value={values.redirect_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                      </span>
                      <InputField
                        className={"did-floating-input"}
                        type="text"
                        name="clinic_address"
                        label={"Clinic Address"}
                        value={values.clinic_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeUpdateCustomerModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Spinner isLoading={isLoading || isFetching || isScopesLoading} />
    </div>
  );
};

export default UpdateCustomerModal;
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
