import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerList,
  setPage,
  setPageSize,
  setSearch,
} from "../../redux/slice/customerSlice"; //

import AddCustomerModal from "../../components/AddCustomerModalForAdmin/AddCustomerModalForAdmin";
import UpdateCustomerModal from "../../components/UpdateCustomerModalForAdmin/UpdateCustomerModalForAdmin";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AdminLayout from "../../components/Admin/AdminLayout";
import {
  useDeleteCustomerMutation,
  usePostAllCustomersDataForAdminMutation,
  usePostTestClientConnectionMutation,
} from "../../services/apiService";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/elements/Breadcrumb/Breadcrumb";
const MySwal = withReactContent(Swal);
const $ = window.$;

const AdminCustomerManagement = () => {
  const breadcrumbItems = [
    { label: "Organizations", link: "/admin/organization-management" },
    { label: "Customers" },
  ];
  const { customerList, page, page_size, search, total_pages } = useSelector(
    (state) => state.customer
  );
  const [paginationLength, setPaginationLength] = useState(page_size);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [showUpdateCustomerModal, setShowUpdateCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const dispatch = useDispatch();
  const isDeletingRef = useRef(false);
  const isTestingRef = useRef(false);
  const [postAllCustomersDataForAdmin, { isFetching, isLoading }] =
    usePostAllCustomersDataForAdminMutation();
  const [
    postTestClientConnection,
    { isFetching: isTestFetching, isLoading: istestLoading },
  ] = usePostTestClientConnectionMutation();

  const navigate = useNavigate();
  const [deleteCustomer, { isFetching: isDeleting }] =
    useDeleteCustomerMutation();

  const params = useParams();

  const fetchCustomers = async () => {
    const payload = { page, page_size, search, org_id: params?.orgId };
    const response = await postAllCustomersDataForAdmin({ query: "", payload });
    if (response?.data) {
      dispatch(setCustomerList(response.data));
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [page, page_size, search]);

  const handleTestEhr = (customer) => {
    if (isTestingRef.current) return;
    isTestingRef.current = true;

    if (customer?.secret_details?.length > 0) {
      postTestClientConnection({
        query: "",
        payload: {
          id: customer?.secret_details?.[0]?.id,
          ehr_id: customer?.ehr_id,
        },
      }).then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: res?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        if (page !== 1) {
          dispatch(setPage(1));
        }
        fetchCustomers();
      });
    } else {
      toast.error("Please add Client secret and Client id");
    }

    isTestingRef.current = false;
  };

  const handleDeleteCustomer = async (customerId) => {
    if (isDeletingRef.current) return;
    isDeletingRef.current = true;

    const response = await deleteCustomer({ query: customerId, payload: null });

    if (response?.data) {
      MySwal.fire({
        title: "Success",
        text: response?.data?.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      if (page !== 1) {
        dispatch(setPage(1));
      }
      fetchCustomers();
    }
  };

  const handleViewScopes = (id) => {
    navigate(`subscription`, { state: { subscriptionId: id } });
  };

  const handlePaginationLengthChange = (newPageSize) => {
    setPaginationLength(newPageSize);
    dispatch(setPageSize(newPageSize));
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();

    const table = $("#CustomerListTable").DataTable({
      searching: true,
      order: [],
      data: customerList || [],
      columns: [
        { title: "Clinic Name", data: "clinic_name" },
        { title: "Email", data: "clinic_email" },
        {
          title: "Environment",
          data: null,
          render: (data, type, row) => {
            return row?.secret_details?.[0]?.environment === "user_sandbox"
              ? "User Sandbox"
              : row?.secret_details?.length === 0 && "default_sandbox"
              ? "Default Sandbox"
              : row?.secret_details?.[0]?.environment === "production"
              ? "Production"
              : "";
          },
        },
        {
          title: "Connection Status",
          data: null,
          render: (data, type, row) => {
            if (row?.secret_details?.length === 0) {
              return `<button class="btn btn-sm btn-success">
              <i class="fa fa-check"></i>
            </button>`;
            } else if (
              row?.secret_details?.[0]?.connection_status === "success"
            ) {
              return `<button class="btn btn-sm btn-success">
              <i class="fa fa-check"></i>
            </button>`;
            } else if (
              row?.secret_details?.[0]?.connection_status === "failed"
            ) {
              return `<button class="test-button" data-customer='${JSON.stringify(
                row
              )}'>Test</button>`;
            } else if (
              row?.secret_details?.[0]?.connection_status === null ||
              row?.secret_details?.[0]?.connection_status === ""
            ) {
              return `<button class="test-button" data-customer='${JSON.stringify(
                row
              )}'>Test</button>`;
            } else {
              return "No record found";
            }
          },
        },
        {
          title: "Actions",
          data: null,
          render: (data, type, row) => {
            return `
             <div style="display:flex;gap:6px">
                </button>  
                <button class="view-button" data-customer-id="${row.id}">
               Scopes
              </button>
              <button class="edit-button" data-customer='${JSON.stringify(
                row
              )}'>Edit</button>
              <button class="delete-button btn btn-sm btn-danger" data-customer-id="${
                row.id
              }">
                <i class="fa fa-trash"></i>
              </button>
              </div>`;
          },
        },
      ],
      drawCallback: function () {
        const renderPagination = () => {
          const totalPages = total_pages;
          const currentPage = page;

          const paginationContainer = $(".dataTables_paginate");
          const paginationList = $("<ul>", { class: "pagination" });
          paginationContainer.empty().append(paginationList);

          let startPage = Math.max(1, currentPage - 2);
          let endPage = Math.min(totalPages, currentPage + 2);

          if (totalPages > 5 && currentPage <= 3) {
            endPage = 5;
          } else if (totalPages > 5 && currentPage >= totalPages - 2) {
            startPage = totalPages - 4;
          }

          const firstButton = $("<a>", {
            text: "First",
            class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
            click: function () {
              if (currentPage > 1) {
                dispatch(setPage(1));
                table.page("first").draw(false);
              }
            },
          });

          const previousButton = $("<a>", {
            text: "Previous",
            class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
            click: function () {
              if (currentPage > 1) {
                dispatch(setPage(page - 1));
                table.page("previous").draw(false);
              }
            },
          });

          const nextButton = $("<a>", {
            text: "Next",
            class: `page-link pointer ${
              currentPage === totalPages ? "disabled" : ""
            }`,
            click: function () {
              if (currentPage < totalPages) {
                dispatch(setPage(page + 1));
                table.page("next").draw(false);
              }
            },
          });

          const lastButton = $("<a>", {
            text: "Last",
            class: `page-link pointer ${
              currentPage === totalPages ? "disabled" : ""
            }`,
            click: function () {
              if (currentPage < totalPages) {
                dispatch(setPage(totalPages));
                table.page("last").draw(false);
              }
            },
          });

          const firstListItem = $("<li>", {
            class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
          }).append(firstButton);
          const previousListItem = $("<li>", {
            class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
          }).append(previousButton);
          const nextListItem = $("<li>", {
            class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
          }).append(nextButton);
          const lastListItem = $("<li>", {
            class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
          }).append(lastButton);
          paginationList.append(firstListItem);
          paginationList.append(previousListItem);

          for (let i = startPage; i <= endPage; i++) {
            const pageLink = $("<a>", {
              text: i,
              class: `page-link pointer ${i === currentPage ? "active" : ""}`,
              click: function () {
                dispatch(setPage(i));
                table.page(i - 1).draw(false);
              },
            });

            const listItem = $("<li>", {
              class: `page-item ${i === currentPage ? "active" : ""}`,
            }).append(pageLink);
            paginationList.append(listItem);
          }

          paginationList.append(nextListItem);
          paginationList.append(lastListItem);
        };

        renderPagination();
      },
    });

    table.page.len(page_size).draw();
    $("#CustomerListTable").on("length.dt", function (e, settings, len) {
      handlePaginationLengthChange(len);
    });

    $("#CustomerListTable").on("click", ".view-button", function () {
      const customerId = $(this).data("customer-id");
      handleViewScopes(customerId);
    });

    $("#CustomerListTable").on("click", ".test-button", function () {
      const customer = $(this).data("customer");

      customer?.secret_details?.length > 0 && handleTestEhr(customer);
    });

    $("#CustomerListTable").on("click", ".edit-button", function () {
      const customer = $(this).data("customer");
      setSelectedCustomer(customer);
      setShowUpdateCustomerModal(true);
    });

    $("#CustomerListTable").on("click", ".delete-button", function () {
      const customerId = $(this).data("customer-id");
      handleDeleteCustomer(customerId);
    });

    $("#global-search").on("keyup", function () {
      dispatch(setSearch(this.value));
      table.search(this.value).draw();
    });

    $("#page-size").on("change", function () {
      const newSize = $(this).val();
      dispatch(setPageSize(parseInt(newSize)));
      table.page.len(parseInt(newSize)).draw();
    });

    return () => {
      $("#CustomerListTable").off("click", ".edit-button");
      $("#CustomerListTable").off("click", ".delete-button");
      $("#CustomerListTable").off("click", ".view-button");
      $("#CustomerListTable").off("click", ".test-button");
      $("#CustomerListTable").off("length.dt");
      table.destroy();
    };
  }, [customerList, page, page_size, total_pages]);

  const openAddCustomerModal = () => {
    setShowAddCustomerModal(true);
  };

  const closeAddCustomerModal = () => {
    setShowAddCustomerModal(false);
  };

  const closeUpdateCustomerModal = () => {
    setShowUpdateCustomerModal(false);
  };

  return (
    <AdminLayout>
      <Breadcrumb items={breadcrumbItems} />
      <div className="overview-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="configure-head mb-4 mt-0">
                <div className="same-heading-icon">
                  <h4>Customers List</h4>
                </div>
              </div>
              <div className="date-section">
                <div className="d-flex gap-3">
                  <div className="select-field">
                    <select
                      id="page-size"
                      defaultValue={page_size}
                      onChange={(e) => {
                        handlePaginationLengthChange(e.target.value);
                      }}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="search-field">
                    <div className="input-group">
                      <input
                        type="text"
                        id="global-search"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-success"
                          type="button"
                          id="emailVerifyBtn"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <button
                      className="common-same-button"
                      type="button"
                      onClick={openAddCustomerModal}
                    >
                      Add new Customer
                    </button>
                  </div>
                </div>
              </div>
              <div className="white-box">
                <div className="common-table m-0">
                  <table
                    className="table dt-responsive table-hover nowrap table-striped"
                    style={{ width: "100%" }}
                    id={"CustomerListTable"}
                  ></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddCustomerModal && (
        <div
          className="modal fade show"
          id="addCustomerModal"
          tabIndex={-1}
          aria-labelledby="addCustomerModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <AddCustomerModal
            closeAddCustomerModal={closeAddCustomerModal}
            onCustomerAdded={() => {
              fetchCustomers();
            }}
          />
        </div>
      )}
      {showUpdateCustomerModal && (
        <div
          className="modal fade show"
          id="updateCustomerModal"
          tabIndex={-1}
          aria-labelledby="updateCustomerModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <UpdateCustomerModal
            customer={selectedCustomer}
            closeUpdateCustomerModal={closeUpdateCustomerModal}
            onCustomerUpdated={() => {
              fetchCustomers();
            }}
          />
        </div>
      )}
      <Spinner
        isLoading={isFetching || isDeleting || isLoading || isTestFetching}
      />
    </AdminLayout>
  );
};

export default AdminCustomerManagement;
