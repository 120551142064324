import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import InputField from "../../../components/elements/inputs/InputField";
import CryptoJS from "crypto-js";
import Select, { components } from "react-select";
import {
  usePostEmailSmsDetailsMutation,
  useGetEmailSmsSettingsMutation,
  useGetSmsProvidersQuery,
} from "../../../services/apiService";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.26rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Pending", value: "pending" },
];

const SmsDetails = () => {
  const [initialValues, setInitialValues] = useState({
    id: "",
    provider_name: "",
    account_sid: "",
    auth_token: "",
    api_key: "",
    api_secret: "",
    sender_email: "",
    status: "active",
  });
  const [prevId, setPrevId] = useState("");
  const encryptedOrg = localStorage.getItem("org_data");
  const decryptedOrg = CryptoJS.AES.decrypt(
    encryptedOrg,
    process.env.REACT_APP_CRYPTOJS_SECRETKEY,
    {
      iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
    }
  ).toString(CryptoJS.enc.Utf8);
  const orgData = decryptedOrg && JSON.parse(decryptedOrg);

  const [getEmailSmsSettings, { isLoading: isFetching }] =
    useGetEmailSmsSettingsMutation();
  const { data: providerOptions } = useGetSmsProvidersQuery();

  const [postEmailSmsDetails, { isLoading: isPosting }] =
    usePostEmailSmsDetailsMutation();

  const fetchAndUpdateSettings = async (orgId) => {
    try {
      const response = await getEmailSmsSettings(orgId);
      if (response?.data) {
        const smsSettingsData =
          response?.data?.notification_details &&
          response?.data?.notification_details?.find((notification) => {
            return notification?.notification_type === "sms";
          });

        setPrevId(smsSettingsData?.id);

        setInitialValues({
          ...smsSettingsData,
          provider_name: providerOptions?.sms_providers?.find(
            (option) => option.id === parseInt(smsSettingsData?.provider_name)
          ),
        });
      }
    } catch (error) {
      console.error("Failed to fetch settings:", error);
      toast.error("Failed to fetch settings");
    }
  };

  useEffect(() => {
    fetchAndUpdateSettings(orgData?.master_org?.org_id);
  }, [getEmailSmsSettings, orgData?.master_org?.org_id]);

  const handleSMSDetails = (values, resetForm, setErrors) => {
    const errors = {};

    // Validate SMS Provider
    if (!values.provider_name) {
      errors.provider_name = "SMS provider is required";
    }

    // Validate Account SID
    if (values.provider_name?.id === 5 && !values.account_sid) {
      errors.account_sid = "Account SID is required for this provider";
    }

    // Validate Auth Token
    if (values.provider_name?.id === 5 && !values.auth_token) {
      errors.auth_token = "Auth Token is required for this provider";
    }

    // Validate API Key
    if (values.provider_name?.id === 4 && !values.api_key) {
      errors.api_key = "API Key is required for this provider";
    }

    // Validate API Secret
    if (values.provider_name?.id === 4 && !values.api_secret) {
      errors.api_secret = "API Secret is required for this provider";
    }

    // Validate Sender Mobile Number
    if (values.provider_name?.id === 4 && !values.sender_email) {
      errors.sender_email = "Sender Mobile number is required";
    }

    // Validate Status
    if (!values.status) {
      errors.status = "Status is required";
    }

    // If there are any errors, set them and prevent submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return; // Prevent form submission if there are validation errors
    }

    const payload = {
      id: prevId || "",
      org_id: orgData?.master_org?.org_id,
      provider_name: values?.provider_name?.id,
      notification_type: "sms",
      account_sid: values?.account_sid,
      auth_token: values?.auth_token,
      api_key: values?.api_key,
      api_secret: values?.api_secret,
      status: values?.status,
    };

    postEmailSmsDetails({ query: "", payload }).then((res) => {
      if (res?.data) {
        MySwal.fire({
          title: "Success",
          text: "Settings Updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        resetForm();
        fetchAndUpdateSettings(orgData?.master_org?.org_id);
      } else {
        toast.error("Failed to update settings");
      }
    });
  };

  const handleStatusChange = (option, setFieldValue) => {
    setFieldValue("status", option.value);
  };

  const handleProviderChange = (selectedOption, setFieldValue, resetForm) => {
    resetForm({
      values: {
        provider_name: selectedOption,
        account_sid: "",
        auth_token: "",
        api_key: "",
        api_secret: "",
        sender_email: "",
      },
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm, setErrors }) =>
                handleSMSDetails(values, resetForm, setErrors)
              }
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log("errors", errors)}
                  <h4 className="login-heading mb-3">SMS Settings</h4>
                  <div className="row mb-0">
                    <div className="col-lg-6 col-md-6 mb-3">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={statusOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Select Status"}
                        value={statusOptions.find(
                          (option) => option?.value === values?.status
                        )}
                        onChange={(option) =>
                          handleStatusChange(option, setFieldValue)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors?.status ? (
                        <div className="error-message">{errors?.status}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                      <Select
                        getOptionLabel={(option) => `${option.provider_name}`}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={providerOptions?.sms_providers}
                        styles={colourStyles}
                        placeholder=""
                        isRequired={true}
                        value={providerOptions?.sms_providers?.find(
                          (option) => option.id === values?.provider_name?.id
                        )}
                        label={"Select SMS Provider"}
                        onChange={(option) =>
                          handleProviderChange(option, setFieldValue, resetForm)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.provider_name && touched.provider_name ? (
                        <div className="error-message">
                          {errors.provider_name}
                        </div>
                      ) : null}
                    </div>

                    {values.provider_name?.id === 5 && (
                      <>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-id-card"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="account_sid"
                                  label={"Account SID"}
                                  value={values?.account_sid}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.account_sid && touched?.account_sid && (
                                <div className="error-message">
                                  {errors?.account_sid}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="auth_token"
                                  label={"Auth Token"}
                                  value={values?.auth_token}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.auth_token && touched?.auth_token && (
                                <div className="error-message">
                                  {errors?.auth_token}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {values.provider_name?.id === 4 && (
                      <>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="fa fa-key" aria-hidden="true" />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="api_key"
                                  label={"API Key"}
                                  value={values?.api_key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.api_key && touched?.api_key && (
                                <div className="error-message">
                                  {errors?.api_key}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="fa fa-key" aria-hidden="true" />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="api_secret"
                                  label={"API Secret"}
                                  value={values?.api_secret}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.api_secret && touched?.api_secret && (
                                <div className="error-message">
                                  {errors?.api_secret}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-id-card"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  classNameLabel={
                                    "did-floating-label did-floating-labe-astrisk"
                                  }
                                  type="text"
                                  name="sender_email"
                                  label={"Sender Mobile number"}
                                  value={values?.sender_email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors?.sender_email &&
                                touched?.sender_email && (
                                  <div className="error-message">
                                    {errors?.sender_email}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isPosting} />
    </div>
  );
};

export default SmsDetails;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
