import moment from "moment";
import React from "react";

const ViewNotificationModal = ({
  notification,
  closeViewNotificationModal,
}) => {
  const parsedData = notification?.data ? JSON.parse(notification.data) : {};

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content shadow-lg border-0">
        <div className="modal-header">
          <h5 className="modal-title" id="addUserModal">
            {parsedData?.subject || "Notification"}
          </h5>
          <button
            type="button"
            className="btn-close text-white"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeViewNotificationModal();
            }}
          />
        </div>
        <div className="modal-body">
          <div className="col-xl-12 col-md-12">
            <div className="card user-card-full border-0">
              <div className="row mx-0">
                <div className="col-sm-12">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h6 className="fw-bold">Notification Details</h6>
                      {notification?.read_at && (
                        <h5>
                          <span className="badge bg-success">
                            {moment(notification?.read_at).fromNow()}
                          </span>
                        </h5>
                      )}
                    </div>
                    <div className="row pt-3">
                      {parsedData?.link && (
                        <div className="col-sm-4 mb-3">
                          <p className="fw-bold mb-1">Link</p>
                          <a
                            href={parsedData.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-primary"
                          >
                            {parsedData.link}
                          </a>
                        </div>
                      )}
                      {parsedData?.content && (
                        <div className="col-sm-12 mt-3">
                          <p className="fw-bold mb-1">Content</p>
                          <div
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: parsedData.content,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotificationModal;
