import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import InputField from "../../components/elements/inputs/InputField";
import { Formik } from "formik";
import Select, { components } from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { usePostUserRegisterMutation } from "../../services/apiService";
import Spinner from "../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import { registerSchema } from "../../utils/validationSchema/validation";

const countryCode = require("../../utils/data/CountryCodes.json");

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    minHeight: "auto",
    minWidth: "39px",
    width: "40px",
    padding: "0px",

    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    minHeight: "30px",

    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const Register = () => {
  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [newPasswordIcon, setNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [confirmNewPasswordIcon, setConfirmNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );

  const togglePasswordVisibility = (type, setType, icon, setIcon) => {
    if (type === "password") {
      setType("text");
      setIcon("fa fa-fw fa-eye field-icon toggle-password");
    } else {
      setType("password");
      setIcon("fa fa-fw fa-eye-slash field-icon toggle-password");
    }
  };

  const [
    postUserRegister,
    { data, isLoading, isSuccess, isError, error, isFetching },
  ] = usePostUserRegisterMutation();

  const onSubmit = async (values, resetForm) => {
    const payload = {
      org_name: values?.org_name,
      org_email: values?.org_email,
      password: values?.confirmNewPassword,
      org_mobile: values?.org_mobile
        ? values?.country_code + "-" + values?.org_mobile
        : "",
      org_url: values?.org_url,
      f_name: values?.f_name,
      l_name: values?.l_name,
      m_name: values?.m_name,
      mobile: values?.mobile ? values?.c_code + "-" + values?.mobile : "",
    };

    postUserRegister({ query: "", payload: payload })
      .then((res) => {
        if (res?.data) {
          toast.success(
            "Account created! Please check your email to activate."
          );
          resetForm();
          navigate("/login");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className="login-main-section add-back-gradient">
        <div className="top"></div>
        <div className="bottom"></div>
        <div className="container">
          <div className="row m-0 justify-content-center">
            <div className="col-lg-11 col-md-12">
              <div className="inner-box back-color-blue">
                <div className="row ">
                  <div className="col-lg-5 ">
                    <div
                      className="left-side"
                      style={{ background: "transparent" }}
                    >
                      <img
                        src="/assets/images/newLogo.png"
                        className="img-fluid image"
                        alt="logo"
                      />
                      <h3 className="poppins-semibold logo">
                        Data-<span>fusion</span>
                      </h3>
                      <div className="slider-common">
                        <Slider {...settings}>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/cover-photo.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Effortless EMR Integration</h3>
                              <p>
                                Connect Epic, Cerner, Athenahealth, and more -
                                all in one place.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/109.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Secure Cloud Storage</h3>
                              <p>
                                Store your patient data securely in the cloud,
                                ensuring compliance and accessibility.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/110.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Unlock Big Data Potential</h3>
                              <p>
                                Gain valuable insights through advanced
                                analytics, improving patient outcomes and
                                driving research.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/111.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Empower Informed Decisions</h3>
                              <p>
                                Make data-driven choices for better patient
                                care, resource allocation, and population health
                                management.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/112.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Simplify Complex Data Workflow</h3>
                              <p>
                                Our user-friendly platform streamlines data
                                access and analysis, saving you valuable time
                                and resources.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/113.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Future-proof Your Practice</h3>
                              <p>
                                Embrace the power of data to stay ahead of the
                                curve and deliver exceptional patient care.
                              </p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12 d-flex align-items-stretch back-color-white">
                    <div className="d-flex align-items-center">
                      <div className="right-side">
                        <h2>Join the Data-Fusion Revolution</h2>
                        <p>Simplify EMR integration and data migration.</p>

                        <Formik
                          initialValues={{
                            f_name: "",
                            m_name: "",
                            l_name: "",
                            mobile: "",
                            org_name: "",
                            org_email: "",
                            org_url: "",
                            org_mobile: "",
                            newPassword: "",
                            confirmNewPassword: "",
                            country_code: "+1",
                            c_code: "+1",
                          }}
                          onSubmit={(values, { resetForm }) =>
                            onSubmit(values, resetForm)
                          }
                          validationSchema={registerSchema}
                          validateOnChange={false}
                          validateOnBlur={false}
                        >
                          {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                            resetForm,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <h4 className="login-heading">
                                User Information
                              </h4>
                              <div className="row mb-0">
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className=" input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-user"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="f_name"
                                          label={"First Name"}
                                          value={values.f_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors?.f_name && touched?.f_name ? (
                                        <div className="error-message">
                                          {errors?.f_name}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-user"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label"}
                                          type="text"
                                          name="m_name"
                                          label={"Middle Name"}
                                          value={values?.m_name || ""}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors?.m_name && touched?.m_name ? (
                                        <div className="error-message">
                                          {errors?.m_name}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-0">
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className=" input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-user"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="l_name"
                                          label={"Last Name"}
                                          value={values.l_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors?.l_name && touched?.l_name ? (
                                        <div className="error-message">
                                          {errors?.l_name}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className="input-group  d-flex align-items-center flex-nowrap">
                                        <span
                                          className="input-group-text p-0"
                                          style={{ width: "auto" }}
                                          id="basic-addon1"
                                        >
                                          <Select
                                            onChange={(e) =>
                                              setFieldValue("c_code", e.label)
                                            }
                                            placeholder={""}
                                            readOnly={true}
                                            styles={colourStyles}
                                            isSearchable={true}
                                            options={countryCode}
                                            defaultValue={{
                                              label: "+1",
                                              value: "CA",
                                            }}
                                            components={{
                                              Control,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label"}
                                          type="text"
                                          name="mobile"
                                          label={"User Contact"}
                                          value={
                                            values?.mobile
                                              ?.replace(/[^\d]/g, "")
                                              .replace(
                                                /(\d{3})(\d{3})(\d{4})/,
                                                "$1-$2-$3"
                                              ) || ""
                                          }
                                          maxLength={12}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {(errors.mobile && touched.mobile) ||
                                      (errors?.c_code && touched.c_code) ? (
                                        <div className="error-message">
                                          {errors.mobile || errors?.c_code}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h4 className="login-heading">
                                Business Information
                              </h4>
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className=" input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-envelope"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="email"
                                          name="org_email"
                                          label={"Company Email"}
                                          value={values.org_email}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors?.org_email &&
                                      touched?.org_email ? (
                                        <div className="error-message">
                                          {errors?.org_email}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="mb-3">
                                    <div className="input-group  d-flex align-items-center flex-nowrap">
                                      <span
                                        className="input-group-text p-0"
                                        style={{ width: "auto" }}
                                        id="basic-addon1"
                                      >
                                        <Select
                                          onChange={(e) =>
                                            setFieldValue(
                                              "country_code",
                                              e.label
                                            )
                                          }
                                          placeholder={""}
                                          readOnly={true}
                                          styles={colourStyles}
                                          isSearchable={true}
                                          options={countryCode}
                                          defaultValue={{
                                            label: "+1",
                                            value: "CA",
                                          }}
                                          components={{
                                            Control,
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                        />
                                      </span>
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="org_mobile"
                                        label={"Organization Contact"}
                                        value={
                                          values?.org_mobile
                                            ?.replace(/[^\d]/g, "")
                                            .replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "$1-$2-$3"
                                            ) || ""
                                        }
                                        maxLength={12}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    {(errors.org_mobile &&
                                      touched.org_mobile) ||
                                    (errors?.country_code &&
                                      touched.country_code) ? (
                                      <div className="error-message">
                                        {errors.org_mobile ||
                                          errors?.country_code}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className=" input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-building"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="org_name"
                                          label={"Company Name"}
                                          value={values.org_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors.org_name && touched.org_name ? (
                                        <div className="error-message">
                                          {errors.org_name}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div>
                                    <div className="mb-3">
                                      <div className=" input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-link"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="org_url"
                                          label={"Organization's website"}
                                          value={values.org_url}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors.org_url && touched.org_url ? (
                                        <div className="error-message">
                                          {errors.org_url}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="mb-3">
                                    <div className=" input-group">
                                      <span className="input-group-text">
                                        <i
                                          className="fa fa-lock"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type={newPasswordType}
                                        name="newPassword"
                                        label={"New Password"}
                                        value={values.newPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span
                                        className={newPasswordIcon}
                                        onClick={() =>
                                          togglePasswordVisibility(
                                            newPasswordType,
                                            setNewPasswordType,
                                            newPasswordIcon,
                                            setNewPasswordIcon
                                          )
                                        }
                                      ></span>
                                    </div>
                                    {errors.newPassword &&
                                    touched.newPassword ? (
                                      <div className="error-message">
                                        {errors.newPassword}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="mb-3">
                                    <div className=" input-group">
                                      <span className="input-group-text">
                                        <i
                                          className="fa fa-lock"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type={confirmNewPasswordType}
                                        name="confirmNewPassword"
                                        label={"Confirm New Password"}
                                        value={values.confirmNewPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span
                                        className={confirmNewPasswordIcon}
                                        onClick={() =>
                                          togglePasswordVisibility(
                                            confirmNewPasswordType,
                                            setConfirmNewPasswordType,
                                            confirmNewPasswordIcon,
                                            setConfirmNewPasswordIcon
                                          )
                                        }
                                      ></span>
                                    </div>
                                    {errors.confirmNewPassword &&
                                    touched.confirmNewPassword ? (
                                      <div className="error-message">
                                        {errors.confirmNewPassword}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex pb-1">
                                <span>
                                  <label className="label check-terms">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      required
                                      style={{ marginTop: "0px" }}
                                      className="form-check-input"
                                      id="remember_me"
                                      name="remember_me"
                                      value="remember_me"
                                    />{" "}
                                    I agree to Data-Fusion
                                    <a
                                      href="https://localhost:3000/terms-and-conditions"
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#50B5FF",
                                      }}
                                    >
                                      T&C
                                    </a>{" "}
                                    and{" "}
                                    <a
                                      href="https://localhost:3000/privacy-policy"
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#50B5FF",
                                      }}
                                    >
                                      Privacy Policy
                                    </a>
                                    .
                                  </label>
                                </span>
                              </div>

                              <button
                                type="submit"
                                className="common-btn w-100"
                              >
                                Submit
                              </button>
                              <p className="forgot-link text-end mt-0 mb-0">
                                <Link to="/" className="btn btn-link">
                                  Back to Login
                                </Link>
                              </p>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </>
  );
};

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export default Register;
