import * as yup from "yup";

//Regular Expression
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
var regex = /\b(\w{1})(\w+)(\w)\b/g;
const emailRegexp =
  /^(?!.*@(gmail\.com|hotmail\.com|yahoo\.com|rediff\.com|zoho\.com)$).*@[\w.-]+\.[a-zA-Z]{2,}$/;
const simpleEmailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const urlRegexp = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*\/?$/;

// login schema
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Please enter valid email")
    .email("Invalid email"),
  password: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
});
const registerSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  newPassword: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmNewPassword: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Both password should match")
    .required("Please type password again"),
  org_email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(emailRegexp, "Please enter Organization email.")
    .email("Invalid email"),
  org_url: yup
    .string()
    .trim()
    .required("Organization website is required")
    .matches(urlRegexp, "Invalid website URL"),

  org_mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Organization Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),

  country_code: yup
    .string()
    .required("Country code is required")
    .min(2)
    .max(6, "Wrong country code"),

  org_name: yup
    .string()
    .required("Organization name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
});
const BasicDetailSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  org_email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(emailRegexp, "Please enter organization email")
    .email("Invalid email"),
  org_url: yup
    .string()
    .trim()
    .required("Organization website is required")
    .matches(urlRegexp, "Invalid website URL"),

  org_mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),
  country_code: yup
    .string()
    .required("Country code is required")
    .min(2)
    .max(6, "Wrong country code"),
  org_name: yup
    .string()
    .required("Organization name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
});

const BasicDetailSchemaForAdminAndUser = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),
  country_code: yup
    .string()
    .required("Country code is required")
    .min(2)
    .max(6, "Wrong country code"),
});

const resetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmNewPassword: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Both password should match")
    .required("Please type password again"),
});
const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Old password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  newPassword: yup
    .string()
    .required("New password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmNewPassword: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Both password should match")
    .required("Please type password again"),
});

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Please enter valid email")
    .email("Invalid email"),
});

const addUserSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  password: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Invalid email")
    .email("Invalid email"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .min(10, "Contact number must be exactly 10 digits")
    .max(10, "Contact number must be exactly 10 digits"),
});

const updateUserSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Invalid email")
    .email("Invalid email"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .min(10, "Contact number must be exactly 10 digits")
    .max(10, "Contact number must be exactly 10 digits"),
});

const addUserByAdminSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  password: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Invalid email")
    .email("Invalid email"),
  org_id: yup.string().required("Organization is required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .min(10, "Contact number must be exactly 10 digits")
    .max(10, "Contact number must be exactly 10 digits"),
});

const updateUserByAdminSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(simpleEmailRegexp, "Invalid email")
    .email("Invalid email"),
  org_id: yup.string().required("Organization is required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .min(10, "Contact number must be exactly 10 digits")
    .max(10, "Contact number must be exactly 10 digits"),
});

const addCustomerSchema = yup.object().shape({
  ehr_id: yup.string().required("EHR Provider is required"),
  clinic_name: yup.string().required("Clinic Name is required"),
  clinic_email: yup
    .string()
    .email("Invalid email format")
    .required("Clinic Email is required"),
  environment: yup
    .string()
    .oneOf(
      ["default_sandbox", "user_sandbox", "production"],
      "Invalid environment"
    )
    .required("Environment is required"),

  clinic_scope: yup
    .array()
    .of(yup.number())
    .required("Scope is required")
    .min(1, "At least one scope is required"),
});
const updateCustomerSchema = yup.object().shape({
  ehr_id: yup.string().required("EHR Provider is required"),
  clinic_name: yup.string().required("Clinic Name is required"),
  clinic_email: yup
    .string()
    .email("Invalid email format")
    .required("Clinic Email is required"),
  environment: yup
    .string()
    .oneOf(
      ["default_sandbox", "user_sandbox", "production"],
      "Invalid environment"
    )
    .required("Environment is required"),

  clinic_scope: yup
    .array()
    .of(yup.number())
    .required("Scope is required")
    .min(1, "At least one scope is required"),
});

const addOrganizationSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  org_email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(emailRegexp, "Please enter organization email")
    .email("Invalid email"),
  org_url: yup
    .string()
    .trim()
    .required("Organization website is required")
    .matches(urlRegexp, "Invalid website URL"),

  org_mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Organization Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),

  country_code: yup
    .string()
    .required("Country code is required")
    .min(2)
    .max(6, "Wrong country code"),
  org_name: yup
    .string()
    .required("Organization name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
});

const updateOrganizationSchema = yup.object().shape({
  f_name: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  l_name: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),

  org_email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(emailRegexp, "Please enter organization email")
    .email("Invalid email"),
  org_url: yup
    .string()
    .trim()
    .required("Organization website is required")
    .matches(urlRegexp, "Invalid website URL"),

  org_mobile: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),
  country_code: yup
    .string()
    .required("Country code is required")
    .min(2)
    .max(6, "Wrong country code"),
  org_name: yup
    .string()
    .required("Organization name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
});

// Define validation schemas for each cloud provider
const s3ValidationSchema = yup.object().shape({
  access_key_id: yup.string().required("Access Key Id is required"),
  secret_access_key: yup.string().required("Secret Access Key is required"),
  region: yup.string().required("Region is required"),
  bucket_name: yup.string().required("Bucket Name is required"),
});

const azureValidationSchema = yup.object().shape({
  account_name: yup.string().required("Account Name is required"),
  account_key: yup.string().required("Account Key is required"),
  container_name: yup.string().required("Container Name is required"),
  connection_string: yup.string().required("Connection String is required"),
});

const googleCloudValidationSchema = yup.object().shape({
  // project_id: yup.string().required("Project Id is required"),
  service_account_key: yup.string().required("JSON data is required"),
  bucket_name: yup.string().required("Bucket Name is required"),
  // credentials_json: yup.string().required("Credentials JSON is required"),
});

// Define a function to get the validation schema based on selected provider
const getValidationSchema = (selectedProvider) => {
  switch (selectedProvider) {
    case 3:
      return s3ValidationSchema;
    case 1:
      return azureValidationSchema;
    case 2:
      return googleCloudValidationSchema;
    default:
      return yup.object().shape({
        storage_provider: yup.string().required("Storage provider is required"),
      });
  }
};

const scheduleValidationSchema = yup.object().shape({
  start_time: yup.string().required("Start time is required"),
  clinic: yup.object().required("Please select a clinic"),
  scope: yup.object().required("Please select a scope"),

  status: yup.string().required("Please select a status"),
  recurrence_pattern: yup.string().required("Recurrence pattern is required"),
});

const EmailSMSValidationSchema = yup.object().shape({
  status: yup.string().required("Status is required"),

  sms_provider_name: yup
    .object()
    .shape({
      id: yup.string().required("Email provider is required"),
    })
    .nullable()
    .required("Email provider is required"),

  account_sid: yup
    .string()
    .required("Account SID is required")
    .matches(/^[a-zA-Z0-9]+$/, "Account SID must be alphanumeric"),

  auth_token: yup
    .string()
    .required("Auth Token is required")
    .matches(/^[a-zA-Z0-9]+$/, "Auth Token must be alphanumeric"),

  api_key: yup
    .string()
    .required("API Key is required")
    .matches(/^[a-zA-Z0-9]+$/, "API Key must be alphanumeric"),

  api_secret: yup
    .string()
    .required("API Secret is required")
    .matches(/^[a-zA-Z0-9]+$/, "API Secret must be alphanumeric"),
});

export {
  loginSchema,
  registerSchema,
  resetPasswordSchema,
  emailSchema,
  changePasswordSchema,
  BasicDetailSchema,
  addUserSchema,
  updateUserSchema,
  addUserByAdminSchema,
  updateUserByAdminSchema,
  addCustomerSchema,
  updateCustomerSchema,
  addOrganizationSchema,
  updateOrganizationSchema,
  BasicDetailSchemaForAdminAndUser,
  getValidationSchema,
  scheduleValidationSchema,
  EmailSMSValidationSchema,
};
