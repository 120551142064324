import { useEffect, useState } from "react";
import "./App.css";
import { BaseRoutes } from "./routes";
import socketIOClient from "socket.io-client";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import withReactContent from "sweetalert2-react-content";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "./config/config";
const MySwal = withReactContent(Swal);

function App() {
  const [socket, setSocket] = useState(null);

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);
    setSocket(socket);

    // Register the user
    socket.emit("registerUser", userData?.user?.id);

    // Listen for notifications
    socket.on("notification", (notification) => {
      console.log(notification, "== received notification");
      // Show the notification using SweetAlert
      MySwal.fire({
        title: JSON.parse(notification?.message)?.subject,
        text: JSON.parse(notification?.message)?.content, // Assuming the notification has a message property
        icon: "info",
        showCancelButton: true, // Shows cancel button alongside 'Go To'
        confirmButtonText: "Go To", // Label the confirm button as "Go To"
        html: `<a href="${
          JSON.parse(notification?.message)?.link
        }" target="_blank">Click here if not redirected</a>`, // Link in case redirect fails
        preConfirm: () => {
          // Handle the redirection on confirm
          const link = JSON.parse(notification?.message)?.link;
          if (link) {
            window.location.href = link; // Redirect to the link
          }
        },
      });
    });

    // Cleanup on component unmount
    // return () => {
    //   socket.disconnect();
    // };
  }, [userData?.user?.id]);

  return <BaseRoutes />;
}

export default App;
