import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import InputField from "../elements/inputs/InputField";
import Select, { components } from "react-select";
import { decodeToken } from "react-jwt";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./AddScheduleModal.css";

import {
  usePostAddScheduleMutation,
  usePostAllCustomersDataForAdminMutation,
  usePostOrgEhrConfigByIdMutation,
} from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { setCustomerList } from "../../redux/slice/customerSlice";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { scheduleValidationSchema } from "../../utils/validationSchema/validation";
import Spinner from "../elements/Spinner/Spinner";
import { generateTimezoneList } from "../../utils/commonFunction";

const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
    "&:focus": {
      // border: "none",
    },
    "&:active": {
      // border: "none",
    },
    "&:hover": {
      // border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const recurrencePatternOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const recurrenceMonthlyTypeOptions = [
  { value: "day", label: "Day" },
  { value: "week_day", label: "Week Day" },
];

const recurrenceYearlyTypeOptions = [
  { value: "day", label: "Day" },
  { value: "week_day", label: "Week Day" },
  { value: "month_day", label: "Month and Day" },
];

const recurrenceEndTypeOptions = [
  { value: "no_end", label: "No End" },
  { value: "after", label: "After" },
  { value: "by", label: "By" },
];

const recurrenceMonthlyWeekOptions = [
  { value: "first", label: "First" },
  { value: "second", label: "Second" },
  { value: "third", label: "Third" },
  { value: "fourth", label: "Fourth" },
  { value: "last", label: "Last" },
];

const recurrenceYearlyWeekOptions = [
  { value: "first", label: "First" },
  { value: "second", label: "Second" },
  { value: "third", label: "Third" },
  { value: "fourth", label: "Fourth" },
  { value: "last", label: "Last" },
];

const recurrenceYearlyMonthOptions = [
  { value: "january", label: "January" },
  { value: "february", label: "February" },
  { value: "march", label: "March" },
  { value: "april", label: "April" },
  { value: "may", label: "May" },
  { value: "june", label: "June" },
  { value: "july", label: "July" },
  { value: "august", label: "August" },
  { value: "september", label: "September" },
  { value: "october", label: "October" },
  { value: "november", label: "November" },
  { value: "december", label: "December" },
];

const daysOfWeek = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

const AddScheduleModal = ({ closeAddScheduleModal, onScheduleAdded }) => {
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [scopeList, setScopeList] = useState([]);
  const encryptedOrg = localStorage.getItem("org_data");
  const decryptedOrg = CryptoJS.AES.decrypt(
    encryptedOrg,
    process.env.REACT_APP_CRYPTOJS_SECRETKEY,
    {
      iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
    }
  ).toString(CryptoJS.enc.Utf8);
  const orgData = JSON.parse(decryptedOrg);

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const { customerList } = useSelector((state) => state.customer);

  const [
    postAddSchedule,
    { isFetching: isScheduleJobAdding, isLoading: isScheduleJobLoading },
  ] = usePostAddScheduleMutation();

  const [
    postAllCustomersDataForAdmin,
    { isFetching: isCustomerFetching, isLoading: isCustomerLoading },
  ] = usePostAllCustomersDataForAdminMutation();

  const [postOrgEhrConfigById, { isFetching, isLoading }] =
    usePostOrgEhrConfigByIdMutation();

  const handleCustomerChange = (selectedOption, setFieldValue) => {
    setSelectedCustomer(selectedOption ? selectedOption.id : "");
    setFieldValue("clinic", selectedOption);

    postOrgEhrConfigById({
      query: "",
      payload: { id: selectedOption.id },
    }).then((res) => {
      if (res?.data) {
        setScopeList(res?.data?.org_ehr_config?.scopes);
      }
    });
  };

  const handleScopeChange = (selectedOption, setFieldValue) => {
    setFieldValue("scope", selectedOption);
  };
  const handleStatusChange = (option, setFieldValue) => {
    setFieldValue("status", option.value);
  };

  const timezoneList = generateTimezoneList();

  const handleAddSchedule = (values, resetForm, setErrors) => {
    const errors = {};
    if (!values.timezone) {
      errors.timezone = "Please select Timezone";
    }
    // Check for recurrence pattern and validate accordingly
    if (!values.recurrence_pattern) {
      errors.recurrence_pattern = "Recurrence pattern is required";
    } else {
      // Daily Recurrence Validation
      if (values.recurrence_pattern === "daily") {
        if (
          !values.recurrence_day_interval ||
          values.recurrence_day_interval < 1
        ) {
          errors.recurrence_day_interval = "Day interval must be at least 1";
        }
      }

      // Weekly Recurrence Validation
      if (values.recurrence_pattern === "weekly") {
        if (
          !values.recurrence_week_day ||
          values?.recurrence_week_day?.length === 0
        ) {
          errors.recurrence_week_day = "Select at least one week day";
        }
      }

      // Monthly Recurrence Validation
      if (values.recurrence_pattern === "monthly") {
        if (!values.recurrence_monthly_type) {
          errors.recurrence_monthly_type =
            "Monthly recurrence type is required";
        }

        if (
          values.recurrence_monthly_type === "day" &&
          (!values.recurrence_monthly_day ||
            values.recurrence_monthly_day < 1 ||
            values.recurrence_monthly_day > 31)
        ) {
          errors.recurrence_monthly_day =
            "Recurrence monthly day is required and day must be between 1 and 31";
        }

        if (
          values.recurrence_monthly_type === "week_day" &&
          (!values.recurrence_monthly_week ||
            !values.recurrence_monthly_week_day)
        ) {
          errors.recurrence_monthly_week = "Recurrence week  is required";
          errors.recurrence_monthly_week_day =
            "Recurrence week day is required";
        }
      }

      // Yearly Recurrence Validation
      if (values.recurrence_pattern === "yearly") {
        if (!values.recurrence_yearly_type) {
          errors.recurrence_yearly_type = "Yearly recurrence type is required";
        }

        if (values.recurrence_yearly_type === "day") {
          // Check if the yearly day is provided
          if (!values.recurrence_yearly_day) {
            errors.recurrence_yearly_day = "Recurrence yearly day is required";
          } else if (
            values.recurrence_yearly_day < 1 ||
            values.recurrence_yearly_day > 31
          ) {
            errors.recurrence_yearly_day = "Day must be between 1 and 31";
          }

          // Check if the yearly month is provided
          if (!values.recurrence_yearly_month) {
            errors.recurrence_yearly_month =
              "Recurrence yearly month is required";
          }
        }

        if (values.recurrence_yearly_type === "week_day") {
          // Check if the yearly week is provided
          if (!values.recurrence_yearly_week) {
            errors.recurrence_yearly_week =
              "Recurrence yearly week is required";
          }

          // Check if the yearly week day is provided
          if (!values.recurrence_yearly_week_day) {
            errors.recurrence_yearly_week_day =
              "Recurrence yearly week day is required";
          }

          // Check if the yearly month is provided
        }
        if (values.recurrence_yearly_type === "month_day") {
          // Check if the yearly month is provided
          if (!values.recurrence_yearly_month) {
            errors.recurrence_yearly_month =
              "Recurrence yearly month is required";
          }

          // Check if the yearly day is provided
          if (!values.recurrence_yearly_day) {
            errors.recurrence_yearly_day = "Recurrence yearly day is required";
          } else if (
            values.recurrence_yearly_day < 1 ||
            values.recurrence_yearly_day > 31
          ) {
            errors.recurrence_yearly_day = "Day must be between 1 and 31";
          }
        }
      }
    }

    // Validate recurrence end type
    if (!values.recurrence_end_type) {
      errors.recurrence_end_type = "Recurrence end type is required";
    } else if (
      values.recurrence_end_type === "after" &&
      (!values.recurrence_end_after_occurrences ||
        values.recurrence_end_after_occurrences < 1)
    ) {
      errors.recurrence_end_after_occurrences =
        "Occurrences must be at least 1";
    } else if (
      values.recurrence_end_type === "by" &&
      !values.recurrence_end_by_date
    ) {
      errors.recurrence_end_by_date = "End by date is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return; // Prevent form submission if there are validation errors
    }
    const payload = {
      timezone: values?.timezone,
      org_id: orgData?.master_org?.org_id,
      org_name: orgData?.master_org?.org_name,
      clinic_name: values?.clinic?.clinic_name,
      start_time: values.start_time,
      recurrence_pattern: values.recurrence_pattern,
      recurrence_day_interval: values.recurrence_day_interval,
      api_name: values?.scope?.ehr_scope_name,
      api_url: values?.scope?.ehr_request_url,
      recurrence_week_day: values.recurrence_week_day
        ? JSON.stringify(values.recurrence_week_day)
        : "",
      scope_id: values?.scope?.ehr_scope_id,
      clinic_id: values?.clinic?.id,
      body: values?.body,
      recurrence_end_type: values.recurrence_end_type,
      recurrence_end_after_occurrences:
        values.recurrence_end_type === "after"
          ? values.recurrence_end_after_occurrences
          : null,
      recurrence_end_by_date:
        values.recurrence_end_type === "by"
          ? values.recurrence_end_by_date
          : null,
      recurrence_monthly_type: values.recurrence_monthly_type,
      recurrence_monthly_day: values.recurrence_monthly_day,
      recurrence_monthly_week: values.recurrence_monthly_week,
      recurrence_monthly_week_day: values.recurrence_monthly_week_day,
      recurrence_yearly_type: values.recurrence_yearly_type,
      recurrence_yearly_day: values.recurrence_yearly_day,
      recurrence_yearly_week: values.recurrence_yearly_week,
      recurrence_yearly_week_day: values.recurrence_yearly_week_day,
      recurrence_yearly_month: values.recurrence_yearly_month,
      user_id: userData?.user?.id,
      status: values?.status,
    };

    postAddSchedule({ query: "", payload })
      .then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: "Customer Details Added successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            resetForm();
            onScheduleAdded();
            closeAddScheduleModal();
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCustomers = async () => {
    const response = await postAllCustomersDataForAdmin({
      query: "",
      payload: {
        page: "1",
        page_size: "10",
        search: "",
        type: "all",
        org_id: orgData?.master_org?.org_id,
      },
    });
    if (response?.data) {
      dispatch(setCustomerList(response.data));
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg modal-fade">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addScheduleModal">
            Add Schedule
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeAddScheduleModal}
          />
        </div>
        <div className="modal-body p-4">
          <Formik
            initialValues={{
              start_time: "",
              clinic: "",
              scope: "",
              body: "",
              status: "",
              recurrence_pattern: "weekly",
              recurrence_day_interval: 0,
              recurrence_week_day: [],
              recurrence_end_type: "",
              recurrence_end_after_occurrences: 0,
              recurrence_end_by_date: "",
              recurrence_monthly_type: "",
              recurrence_monthly_day: "",
              recurrence_monthly_week: "",
              recurrence_monthly_week_day: "",
              recurrence_yearly_type: "",
              recurrence_yearly_day: "",
              recurrence_yearly_week: "",
              recurrence_yearly_week_day: "",
              recurrence_yearly_month: "",
            }}
            validationSchema={scheduleValidationSchema}
            onSubmit={(values, { resetForm, setErrors }) =>
              handleAddSchedule(values, resetForm, setErrors)
            }
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="schedule-form">
                <div className="row">
                  {console.log("values", values)}
                  {console.log("error", errors)}
                  <div className="col-md-6 mb-3">
                    <Select
                      getOptionLabel={(option) => `${option.clinic_name}`}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={customerList}
                      styles={colourStyles}
                      placeholder=""
                      isRequired={true}
                      value={customerList?.find(
                        (option) => option.id === values?.clinic?.id
                      )}
                      label={"Select Clinic"}
                      onChange={(option) => {
                        handleCustomerChange(option, setFieldValue);
                        setFieldValue("scope", null);
                      }}
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.clinic && touched.clinic ? (
                      <div className="error-message">{errors.clinic}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-3">
                    <Select
                      getOptionLabel={(option) =>
                        `${option.resource + "-" + option.ehr_scope_name}`
                      }
                      getOptionValue={(option) => option.ehr_scope_id}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={scopeList}
                      styles={colourStyles}
                      placeholder=""
                      isRequired={true}
                      value={scopeList?.find(
                        (option) =>
                          option.ehr_scope_id === values?.scope?.ehr_scope_id
                      )}
                      isDisabled={!selectedCustomer}
                      label={"Select Scope"}
                      onChange={(option) =>
                        handleScopeChange(option, setFieldValue)
                      }
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.scope && touched.scope ? (
                      <div className="error-message">{errors.scope}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <Field
                      as="textarea"
                      className={"did-floating-input"}
                      classNameLabel={
                        "did-floating-label did-floating-label-asterisk"
                      }
                      name="body"
                      placeholder="Request body"
                      rows="6"
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={statusOptions}
                      styles={colourStyles}
                      isRequired={true}
                      placeholder=""
                      label={"Select Status"}
                      value={statusOptions.find(
                        (option) => option?.value === values?.status
                      )}
                      onChange={(option) =>
                        handleStatusChange(option, setFieldValue)
                      }
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors?.status && touched?.status ? (
                      <div className="error-message">{errors?.status}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Select
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => option.id}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={timezoneList}
                      styles={colourStyles}
                      placeholder="Select Timezone"
                      isRequired={true}
                      value={timezoneList.find(
                        (option) => option.id === values?.timezone
                      )}
                      label={"Select Timezone"}
                      onChange={(option) =>
                        setFieldValue("timezone", option.id)
                      }
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.timezone && touched.timezone ? (
                      <div className="error-message">{errors.timezone}</div>
                    ) : null}
                  </div>
                  <div className="col-md-4 mb-3">
                    <InputField
                      className={"did-floating-input"}
                      placeholder="Start Time"
                      classNameLabel={
                        "did-floating-label did-floating-labe-astrisk"
                      }
                      type="time"
                      name="start_time"
                      id="start_time"
                      label={"Start Time"}
                      value={values.start_time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.start_time && touched.start_time && (
                      <div className="error-message">{errors.start_time}</div>
                    )}
                  </div>

                  <div className="col-md-4 mb-3">
                    <Select
                      options={recurrencePatternOptions}
                      styles={colourStyles}
                      placeholder=""
                      isRequired={true}
                      label={"Recurrence Pattern"}
                      value={recurrencePatternOptions.find(
                        (option) => option.value === values.recurrence_pattern
                      )}
                      onChange={(option) => {
                        setFieldValue("recurrence_pattern", option.value);
                        setFieldValue("recurrence_day_interval", "");
                        setFieldValue("recurrence_monthly_type", "");
                        setFieldValue("recurrence_monthly_day", "");
                        setFieldValue("recurrence_monthly_week", "");
                        setFieldValue("recurrence_monthly_week_day", "");
                        setFieldValue("recurrence_yearly_type", "");
                        setFieldValue("recurrence_yearly_day", "");
                        setFieldValue("recurrence_yearly_week", "");
                        setFieldValue("recurrence_yearly_week_day", "");
                        setFieldValue("recurrence_yearly_month", "");
                        setFieldValue("recurrence_week_day", []);
                      }}
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.recurrence_pattern && touched.recurrence_pattern ? (
                      <div className="error-message">
                        {errors.recurrence_pattern}
                      </div>
                    ) : null}
                  </div>
                </div>
                {values.recurrence_pattern === "daily" && (
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <InputField
                        className={"did-floating-input"}
                        classNameLabel={
                          "did-floating-label did-floating-labe-astrisk"
                        }
                        label={"Every"}
                        type="number"
                        name="recurrence_day_interval"
                        id="recurrence_day_interval"
                        min="1"
                        value={values.recurrence_day_interval}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.recurrence_day_interval &&
                      touched.recurrence_day_interval ? (
                        <div className="error-message">
                          {errors.recurrence_day_interval}
                        </div>
                      ) : (
                        <span className="ms-1">day(s)</span>
                      )}
                    </div>
                  </div>
                )}
                {values.recurrence_pattern === "monthly" && (
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <Select
                        options={recurrenceMonthlyTypeOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Recurrence Monthly Type"}
                        isRequired={true}
                        value={recurrenceMonthlyTypeOptions.find(
                          (option) =>
                            option.value === values.recurrence_monthly_type
                        )}
                        onChange={(option) =>
                          setFieldValue("recurrence_monthly_type", option.value)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.recurrence_monthly_type &&
                      touched.recurrence_monthly_type ? (
                        <div className="error-message">
                          {errors.recurrence_monthly_type}
                        </div>
                      ) : null}
                    </div>

                    {values.recurrence_monthly_type === "day" && (
                      <div className="col-md-12 mb-4">
                        <InputField
                          type="number"
                          name="recurrence_monthly_day"
                          id="recurrence_monthly_day"
                          label={"Recurrence monthly day"}
                          min="1"
                          max="31"
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          value={values.recurrence_monthly_day}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.recurrence_monthly_day &&
                        touched.recurrence_monthly_day ? (
                          <div className="error-message">
                            {errors.recurrence_monthly_day}
                          </div>
                        ) : null}
                      </div>
                    )}
                    {values.recurrence_monthly_type === "week_day" && (
                      <div>
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <Select
                              options={recurrenceMonthlyWeekOptions}
                              styles={colourStyles}
                              placeholder=""
                              isRequired={true}
                              value={recurrenceMonthlyWeekOptions.find(
                                (option) =>
                                  option.value ===
                                  values.recurrence_monthly_week
                              )}
                              label={"Recurrence monthly week"}
                              onChange={(option) =>
                                setFieldValue(
                                  "recurrence_monthly_week",
                                  option.value
                                )
                              }
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                            {errors.recurrence_monthly_week &&
                            touched.recurrence_monthly_week ? (
                              <div className="error-message">
                                {errors.recurrence_monthly_week}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-4">
                            <Select
                              options={daysOfWeek}
                              styles={colourStyles}
                              placeholder=""
                              isRequired={true}
                              label={"Recurrence monthly week day"}
                              value={daysOfWeek.find(
                                (option) =>
                                  option.value ===
                                  values.recurrence_monthly_week_day
                              )}
                              onChange={(option) =>
                                setFieldValue(
                                  "recurrence_monthly_week_day",
                                  option.value
                                )
                              }
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                            {errors.recurrence_monthly_week_day &&
                            touched.recurrence_monthly_week_day ? (
                              <div className="error-message">
                                {errors.recurrence_monthly_week_day}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {values.recurrence_pattern === "yearly" && (
                  <div className="">
                    <div className="col-md-12 mb-4">
                      <Select
                        options={recurrenceYearlyTypeOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Recurrence Yearly Type"}
                        isRequired={true}
                        value={recurrenceYearlyTypeOptions.find(
                          (option) =>
                            option.value === values.recurrence_yearly_type
                        )}
                        onChange={(option) =>
                          setFieldValue("recurrence_yearly_type", option.value)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.recurrence_yearly_type &&
                      touched.recurrence_yearly_type ? (
                        <div className="error-message">
                          {errors.recurrence_yearly_type}
                        </div>
                      ) : null}
                    </div>
                    {values.recurrence_yearly_type === "day" && (
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            label={"Recurrence yearly day"}
                            type="number"
                            name="recurrence_yearly_day"
                            id="recurrence_yearly_day"
                            min="1"
                            max="31"
                            value={values.recurrence_yearly_day}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.recurrence_yearly_day &&
                          touched.recurrence_yearly_day ? (
                            <div className="error-message">
                              {errors.recurrence_yearly_day}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <Select
                            options={recurrenceYearlyMonthOptions}
                            styles={colourStyles}
                            placeholder=""
                            isRequired={true}
                            label={"Recurrence yearly Month"}
                            value={recurrenceYearlyMonthOptions.find(
                              (option) =>
                                option.value === values.recurrence_yearly_month
                            )}
                            onChange={(option) =>
                              setFieldValue(
                                "recurrence_yearly_month",
                                option.value
                              )
                            }
                            components={{
                              Control,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                          {errors.recurrence_yearly_month &&
                          touched.recurrence_yearly_month ? (
                            <div className="error-message">
                              {errors.recurrence_yearly_month}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {values.recurrence_yearly_type === "week_day" && (
                      <div>
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <Select
                              options={recurrenceYearlyWeekOptions}
                              styles={colourStyles}
                              placeholder=""
                              label={"Recurrence yearly week"}
                              isRequired={true}
                              value={recurrenceYearlyWeekOptions.find(
                                (option) =>
                                  option.value === values.recurrence_yearly_week
                              )}
                              onChange={(option) =>
                                setFieldValue(
                                  "recurrence_yearly_week",
                                  option.value
                                )
                              }
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                            {errors.recurrence_yearly_week &&
                            touched.recurrence_yearly_week ? (
                              <div className="error-message">
                                {errors.recurrence_yearly_week}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mb-4">
                            <Select
                              options={daysOfWeek}
                              styles={colourStyles}
                              placeholder=""
                              isRequired={true}
                              label={"Recurrence yearly week day"}
                              value={daysOfWeek.find(
                                (option) =>
                                  option.value ===
                                  values.recurrence_yearly_week_day
                              )}
                              onChange={(option) =>
                                setFieldValue(
                                  "recurrence_yearly_week_day",
                                  option.value
                                )
                              }
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                            {errors.recurrence_yearly_week_day &&
                            touched.recurrence_yearly_week_day ? (
                              <div className="error-message">
                                {errors.recurrence_yearly_week_day}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {values.recurrence_yearly_type === "month_day" && (
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <Select
                            options={recurrenceYearlyMonthOptions}
                            styles={colourStyles}
                            label={"Recurrence yearly month"}
                            placeholder=""
                            isRequired={true}
                            value={recurrenceYearlyMonthOptions.find(
                              (option) =>
                                option.value === values.recurrence_yearly_month
                            )}
                            onChange={(option) =>
                              setFieldValue(
                                "recurrence_yearly_month",
                                option.value
                              )
                            }
                            components={{
                              Control,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                          {errors.recurrence_yearly_month &&
                          touched.recurrence_yearly_month ? (
                            <div className="error-message">
                              {errors.recurrence_yearly_month}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-4">
                          <InputField
                            type="number"
                            name="recurrence_yearly_day"
                            label={"Recurrence yearly day"}
                            id="recurrence_yearly_day"
                            min="1"
                            max="31"
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            value={values.recurrence_yearly_day}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.recurrence_yearly_day &&
                          touched.recurrence_yearly_day ? (
                            <div className="error-message">
                              {errors.recurrence_yearly_day}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {values.recurrence_pattern === "weekly" && (
                  <div className="col-md-12 mb-4">
                    <Select
                      options={daysOfWeek}
                      styles={colourStyles}
                      placeholder=""
                      isRequired={true}
                      label={"Recurrence Week Day"}
                      value={daysOfWeek?.filter((option) =>
                        values.recurrence_week_day.includes(option.value)
                      )}
                      onChange={(option) =>
                        setFieldValue(
                          "recurrence_week_day",
                          option.map((item) => item.value)
                        )
                      }
                      isMulti={true}
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {errors.recurrence_week_day &&
                    touched.recurrence_week_day ? (
                      <div className="error-message">
                        {errors.recurrence_week_day}
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="col-md-12 mb-4">
                  <Select
                    options={recurrenceEndTypeOptions}
                    styles={colourStyles}
                    placeholder=""
                    label={"Recurrence End type"}
                    isRequired={true}
                    value={recurrenceEndTypeOptions.find(
                      (option) => option.value === values.recurrence_end_type
                    )}
                    onChange={(option) => {
                      setFieldValue("recurrence_end_type", option.value);
                      setFieldValue("recurrence_end_after_occurrences", ""); // Clear occurrences
                      setFieldValue("recurrence_end_by_date", ""); // Clear date
                    }}
                    components={{
                      Control,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {errors.recurrence_end_type && touched.recurrence_end_type ? (
                    <div className="error-message">
                      {errors.recurrence_end_type}
                    </div>
                  ) : null}
                </div>

                {values.recurrence_end_type === "after" && (
                  <div className="col-md-6 mb-4">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={
                        "did-floating-label did-floating-labe-astrisk"
                      }
                      type="number"
                      label={"After"}
                      name="recurrence_end_after_occurrences"
                      id="recurrence_end_after_occurrences"
                      value={values.recurrence_end_after_occurrences}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.recurrence_end_after_occurrences &&
                    touched.recurrence_end_after_occurrences ? (
                      <div className="error-message">
                        {errors.recurrence_end_after_occurrences}
                      </div>
                    ) : null}
                  </div>
                )}
                {values.recurrence_end_type === "by" && (
                  <div className="col-md-6 mb-4">
                    <InputField
                      className={"did-floating-input"}
                      classNameLabel={
                        "did-floating-label did-floating-labe-astrisk"
                      }
                      type="date"
                      name="recurrence_end_by_date"
                      id="recurrence_end_by_date"
                      value={values.recurrence_end_by_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.recurrence_end_by_date &&
                    touched.recurrence_end_by_date ? (
                      <div className="error-message">
                        {errors.recurrence_end_by_date}
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-primary w-50 p-2 fw-bold"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Spinner isLoading={isScheduleJobAdding || isScheduleJobLoading} />
    </div>
  );
};

export default AddScheduleModal;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
