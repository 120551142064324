import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Select, { components } from "react-select";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import {
  usePostNotificationDetailsMutation,
  useGetNotificationSettingsMutation,
} from "../../../services/apiService";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../../config/config";

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.26rem 0.75rem",
    "&:focus": {
      // border: "none",
    },
    "&:active": {
      // border: "none",
    },
    "&:hover": {
      // border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const statusOptions = [
  { label: "On", value: "on" },
  { label: "Off", value: "off" },
];

const NotificationDetails = () => {
  const [initialValues, setInitialValues] = useState({
    email: "on",
    sms: "on",
    push: "on",
  });

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const [getNotificationSettings] = useGetNotificationSettingsMutation();
  const [postNotificationDetails] = usePostNotificationDetailsMutation();

  const fetchAndSetInitialValues = async (id) => {
    try {
      const response = await getNotificationSettings(id);
      if (response?.data) {
        const settings = response?.data;

        setInitialValues({
          settings,
        });
      }
    } catch (error) {
      console.error("Failed to fetch notification details:", error);
      toast.error("Failed to fetch notification details");
    }
  };

  useEffect(() => {
    const handleNotificationTabSelected = (event) => {
      fetchAndSetInitialValues(userData?.user?.id);
    };

    window.addEventListener(
      "notificationTabSelected",
      handleNotificationTabSelected
    );

    return () => {
      window.removeEventListener(
        "notificationTabSelected",
        handleNotificationTabSelected
      );
    };
  }, [fetchAndSetInitialValues, userData?.user?.id]);

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      id: userData?.user?.id,
      notification_settings: JSON.stringify(values),
    };
    try {
      await postNotificationDetails({ query: "", payload });
      await fetchAndSetInitialValues(userData?.user?.id);
      toast.success("Notification settings updated successfully!");
      resetForm();
    } catch (error) {
      console.error("Failed to update notification settings:", error);
      toast.error("Failed to update notification settings");
    }
  };

  const handleStatusChange = (type, value, setFieldValue) => {
    setFieldValue(type, value);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h4 className="login-heading mb-3">Notification Settings</h4>
                  <div className="row mb-0">
                    <div className="col-md-4 mb-3">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={statusOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Email Status"}
                        value={statusOptions.find(
                          (option) => option.value === values.email
                        )}
                        onChange={(option) =>
                          handleStatusChange(
                            "email",
                            option.value,
                            setFieldValue
                          )
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={statusOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Sms Status"}
                        value={statusOptions.find(
                          (option) => option.value === values.sms
                        )}
                        onChange={(option) =>
                          handleStatusChange("sms", option.value, setFieldValue)
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={statusOptions}
                        styles={colourStyles}
                        placeholder=""
                        label={"Push Notification Status"}
                        value={statusOptions.find(
                          (option) => option.value === values.push
                        )}
                        onChange={(option) =>
                          handleStatusChange(
                            "push",
                            option.value,
                            setFieldValue
                          )
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={false} />{" "}
      {/* Update this based on actual loading state */}
    </div>
  );
};

export default NotificationDetails;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
