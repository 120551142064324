import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import InputField from "../../../components/elements/inputs/InputField";
import Select, { components } from "react-select";
import "./EHRDetails.css";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import { usePostAddEHRByAdminMutation } from "../../../services/apiService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const methodOptions = [
  { label: "GET", value: "GET" },
  { label: "POST", value: "POST" },
  { label: "PUT", value: "PUT" },
  { label: "DELETE", value: "DELETE" },
];

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
const EHRDetails = () => {
  const [ehr_logo, setEHRLogo] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarError, setAvatarError] = useState("");

  const [
    postAddEHRByAdmin,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePostAddEHRByAdminMutation();

  const [initialValues, setInitialValues] = useState({
    ehr_name: "",
    ehr_logo: "",
    ehr_description: "",
    ehr_scopes: [
      {
        resource: "",
        ehr_scope_name: "",
        ehr_request_url: "",
        ehr_scope_type: "GET",
        ehr_scope_description: "",
      },
    ],
  });

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1.5 * 1024 * 1024) {
      // Validate file format here
      if (file.type === "image/png") {
        setEHRLogo(file);
        setAvatarPreview(URL.createObjectURL(file));
        setAvatarError("");
      } else {
        setAvatarError("File must be in PNG format.");
      }
    } else {
      setAvatarError("File size must be less than 1.5 MB");
    }
  };

  const onSubmit = (values, resetForm) => {
    const formData = new FormData();

    // Append EHR logo if it's selected
    if (ehr_logo) {
      formData.append("ehr_logo", ehr_logo);
    }

    // Append other EHR details
    formData.append("ehr_name", values.ehr_name);
    formData.append("ehr_description", values.ehr_description);

    // Append scopes as a JSON string
    formData.append("ehr_scopes", JSON.stringify(values.ehr_scopes));

    postAddEHRByAdmin({ query: "", payload: formData }).then((res) => {
      //   if (res?.data?.user_data) {
      //     MySwal.fire({
      //       title: "Success",
      //       text: "Profile Updated successfully!",
      //       icon: "success",
      //       confirmButtonText: "OK",
      //     });
      //   }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-7 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                if (!avatarError) {
                  onSubmit({ ...values, ehr_logo }, resetForm);
                } else {
                  toast.error(avatarError);
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              //   validationSchema={BasicDetailSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h4 className="login-heading mb-3">EHR Information</h4>
                  <div className="row mb-0">
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className=" input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="ehr_name"
                              label={"EHR Name"}
                              value={values.ehr_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.ehr_name && touched?.ehr_name ? (
                            <div className="error-message">
                              {errors?.ehr_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <i className="fa fa-info-circle" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          type="text"
                          name="ehr_description"
                          label={"EHR Description"}
                          value={values.ehr_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <i className="fa fa-image" aria-hidden="true" />{" "}
                        </span>
                        <input
                          type="file"
                          accept=".png"
                          onChange={handleAvatarChange}
                          className="form-control"
                        />
                      </div>
                      {(avatarPreview || values?.org_logo) && (
                        <div className="mb-3">
                          <img
                            src={
                              avatarPreview ||
                              process.env.REACT_APP_FILE_URL + values?.org_logo
                            }
                            alt="Avatar Preview"
                            style={{ maxHeight: "100px" }}
                          />
                        </div>
                      )}
                      {avatarError ? (
                        <div className="error-message">{avatarError}</div>
                      ) : (
                        <div
                          style={{
                            color: "#ff5f15",
                            marginTop: "0px",
                            fontWeight: "normal",
                            fontSize: "12px",
                          }}
                        >
                          File size should not exceed 1.5mb.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-12 col-md-12">
                      <div className="scope-count">
                        Total Scopes: {values.ehr_scopes.length}
                      </div>
                      <FieldArray
                        name="ehr_scopes"
                        render={(arrayHelpers) => (
                          <div>
                            {values.ehr_scopes.map((scope, index) => (
                              <div key={index} className="scope-item mb-3">
                                <div className="row mb-0">
                                  <div className="col-lg-6 col-md-6">
                                    <div>
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />{" "}
                                          </span>
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-label-asterisk"
                                            }
                                            type="text"
                                            name={`ehr_scopes[${index}].resource`}
                                            label="Resource"
                                            value={scope.resource}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {errors.ehr_scopes?.[index]?.resource &&
                                        touched.ehr_scopes?.[index]
                                          ?.resource ? (
                                          <div className="error-message">
                                            {errors.ehr_scopes[index].resource}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6">
                                    <div>
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <span className="input-group-text">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            />{" "}
                                          </span>
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-label-asterisk"
                                            }
                                            type="text"
                                            name={`ehr_scopes[${index}].ehr_scope_name`}
                                            label="Scope Name"
                                            value={scope.ehr_scope_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                        {errors.ehr_scopes?.[index]
                                          ?.ehr_scope_name &&
                                        touched.ehr_scopes?.[index]
                                          ?.ehr_scope_name ? (
                                          <div className="error-message">
                                            {
                                              errors.ehr_scopes[index]
                                                .ehr_scope_name
                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-0">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="mb-3">
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          <i
                                            className="fa fa-link"
                                            aria-hidden="true"
                                          />{" "}
                                        </span>
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-label-asterisk"
                                          }
                                          type="text"
                                          name={`ehr_scopes[${index}].ehr_request_url`}
                                          label="Request URL"
                                          value={scope.ehr_request_url}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      {errors.ehr_scopes?.[index]
                                        ?.ehr_request_url &&
                                      touched.ehr_scopes?.[index]
                                        ?.ehr_request_url ? (
                                        <div className="error-message">
                                          {
                                            errors.ehr_scopes[index]
                                              .ehr_request_url
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="col-md-6 mb-3">
                                    <Select
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      options={methodOptions}
                                      styles={colourStyles}
                                      placeholder=""
                                      isRequired={true}
                                      value={{
                                        label: scope.ehr_scope_type,
                                        value: scope.ehr_scope_type,
                                      }}
                                      label={"Select method type"}
                                      onChange={(option) =>
                                        setFieldValue(
                                          `ehr_scopes[${index}].ehr_scope_type`,
                                          option.value
                                        )
                                      }
                                      components={{
                                        Control,
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      }}
                                    />
                                    {errors.ehr_scopes?.[index]
                                      ?.ehr_scope_type &&
                                    touched.ehr_scopes?.[index]
                                      ?.ehr_scope_type ? (
                                      <div className="error-message">
                                        {
                                          errors.ehr_scopes[index]
                                            .ehr_scope_type
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-lg-12 col-md-12">
                                    <div className="mb-3 input-group">
                                      <span className="input-group-text">
                                        <i
                                          className="fa fa-info-circle"
                                          aria-hidden="true"
                                        />{" "}
                                      </span>
                                      <InputField
                                        className={"did-floating-input"}
                                        type="text"
                                        name={`ehr_scopes[${index}].ehr_scope_description`}
                                        label="Scope Description"
                                        value={scope.ehr_scope_description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="remove-scope-btn"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  &times;
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="add-scope-btn"
                              onClick={() =>
                                arrayHelpers.push({
                                  resource: "",
                                  ehr_scope_name: "",
                                  ehr_request_url: "",
                                  ehr_scope_type: "GET",
                                  ehr_scope_description: "",
                                })
                              }
                            >
                              Add Scope
                            </button>
                          </div>
                        )}
                      ></FieldArray>
                    </div>
                  </div>

                  <div className="d-flex pb-1">
                    <span>
                      <label className="label check-terms">
                        {" "}
                        <input
                          type="checkbox"
                          required
                          style={{ marginTop: "0px" }}
                          className="form-check-input"
                          id="remember_me"
                          name="remember_me"
                          value="remember_me"
                        />{" "}
                        I agree to Data-Fusion
                        <a
                          href="https://localhost:3000/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          T&C
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://localhost:3000/privacy-policy"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </span>
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </div>
  );
};

export default EHRDetails;
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
