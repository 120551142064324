import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  page_size: 25,
  search: "",
  scheduledList: [],
  total_pages: 0,
  totalRecords: 0,
};

const schedulerSlice = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    setScheduleList: (state, action) => {
      state.scheduledList = action.payload.scheduled_jobs;
      state.totalRecords = action.payload.total_records;
      state.total_pages = Math.ceil(
        action.payload.total_records / state.page_size
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
      state.page = 1; // Reset to the first page
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setScheduleList, setPage, setPageSize, setSearch } =
  schedulerSlice.actions;

export default schedulerSlice.reducer;
