import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  usePostAllNotificationDataMutation,
  useReadNotificationMutation,
} from "../../services/apiService";

import Spinner from "../../components/elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactDOM from "react-dom";
import Breadcrumb from "../../components/elements/Breadcrumb/Breadcrumb";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import {
  setNotificationList,
  setPage,
  setPageSize,
  setSearch,
  setUnreadCount,
} from "../../redux/slice/notificationSlice";
import { toast } from "react-toastify";
import moment from "moment";
import UserLayout from "../../components/User/UserLayout";
import ViewNotificationModal from "../../components/ViewNotificationModal/ViewNotificationModal";
const MySwal = withReactContent(Swal);
const $ = window.$;

const UserNotificationList = () => {
  const breadcrumbItems = [{ label: "Notifications" }];
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showViewNotificationModal, setShowViewNotificationModal] =
    useState(false);

  const closeViewNotificationModal = async () => {
    fetchNotifications(userData.user.id);
    setShowViewNotificationModal(false);

    console.log("selectedNotification", selectedNotification);

    if (!selectedNotification?.read_at) {
      try {
        await readNotification(selectedNotification?.id);
        await fetchNotifications(userData?.user?.id);
      } catch (error) {
        console.error("Failed to mark notification as read:", error);
        toast.error("Failed to mark notification as read");
      }
    }
  };

  const {
    notificationList,
    page,
    page_size,
    search,
    total_pages,
    totalRecords,
    from,
    to,
  } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const [postAllNotificationData, { isLoading, isFetching }] =
    usePostAllNotificationDataMutation();
  const [
    readNotification,
    { isLoading: isNotificationReading, isFetching: isNotificationFetching },
  ] = useReadNotificationMutation();

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);

  const handleReadNotification = async (notification) => {
    setShowViewNotificationModal(true);
    setSelectedNotification(notification);
  };

  const isDeletingRef = useRef(false);

  const [paginationLength, setPaginationLength] = useState(page_size);

  const fetchNotifications = async (id) => {
    const payload = { id: id, page, page_size, search };
    const response = await postAllNotificationData({
      query: "",
      payload: payload,
    });

    if (response?.data) {
      dispatch(setNotificationList(response?.data));
      dispatch(setUnreadCount(response?.data));
    }
  };

  useEffect(() => {
    fetchNotifications(userData?.user?.id);
  }, [page, page_size, search]);

  const handlePaginationLengthChange = (newPageSize) => {
    setPaginationLength(newPageSize);
    dispatch(setPageSize(newPageSize));
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();

    const renderPagination = () => {
      const table = $("#UserNotificationListTable").DataTable();
      const totalPages = total_pages;
      const currentPage = page;

      const paginationContainer = $(".dataTables_paginate");
      const paginationList = $("<ul>", { class: "pagination" });
      paginationContainer.empty().append(paginationList);

      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (totalPages > 5 && currentPage <= 3) {
        endPage = 5;
      } else if (totalPages > 5 && currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
      const firstButton = $("<a>", {
        text: "<<",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(1));
            table.page("first").draw(false);
          }
        },
      });

      const previousButton = $("<a>", {
        text: "<",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(page - 1));
            table.page("previous").draw(false);
          }
        },
      });

      const nextButton = $("<a>", {
        text: ">",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(page + 1));
            table.page("next").draw(false);
          }
        },
      });
      const lastButton = $("<a>", {
        text: ">>",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(totalPages));
            table.page("last").draw(false);
          }
        },
      });

      const firstListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(firstButton);
      const previousListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(previousButton);
      const nextListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(nextButton);
      const lastListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(lastButton);
      paginationList.append(firstListItem);
      paginationList.append(previousListItem);

      for (let i = startPage; i <= endPage; i++) {
        const pageLink = $("<a>", {
          text: i,
          class: `page-link pointer ${i === currentPage ? "active" : ""}`,
          click: function () {
            dispatch(setPage(i));
            table.page(i - 1).draw(false);
          },
        });

        const listItem = $("<li>", {
          class: `page-item ${i === currentPage ? "active" : ""}`,
        }).append(pageLink);
        paginationList.append(listItem);
      }

      paginationList.append(nextListItem);
      paginationList.append(lastListItem);
    };

    const table = $("#UserNotificationListTable").DataTable({
      searching: true,
      order: [],
      data: notificationList || [],

      columns: [
        {
          title: "Sr",
          data: "id",
          width: 50,
          render: function (data, type, full, meta) {
            return meta.row + 1;
          },
        },
        {
          title: "Heading",
          width: 50,
          data: "data",
          width: 60,
          render: function (data, type, full, meta) {
            return data ? JSON.parse(data)?.subject : "-";
          },
        },
        {
          title: "Content",
          width: 100,
          data: "data",
          render: function (data, type, full, meta) {
            return data
              ? JSON.parse(data)?.content?.length > 0
                ? JSON.parse(data)?.content?.length < 25
                  ? JSON.parse(data)?.content?.substring(0, 10)
                  : JSON.parse(data)?.content?.substring(0, 25) + "..."
                : "-"
              : "-";
          },
        },

        {
          title: "Link",
          data: "data",
          width: 100,
          render: function (data, type, full, meta) {
            return data
              ? JSON.parse(data)?.link
                ? `<a href="${JSON.parse(data)?.link}" target="_blank">${
                    JSON.parse(data)?.link
                  }</a>`
                : "-"
              : "-";
          },
        },
        {
          title: "Time",
          data: "created_at",
          width: 60,
          render: function (data, type, full, meta) {
            return moment(data).fromNow();
          },
        },

        {
          title: "Action",
          width: 60,
        },
      ],
      columnDefs: [
        {
          targets: 4,
          responsivePriority: 2,
        },
        {
          targets: 5,
          responsivePriority: 1,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div>
                <button
                  onClick={() => {
                    handleReadNotification(rowData);
                  }}
                  size="sm"
                  className="w-100 view-button"
                  variant={rowData?.read_at ? "outline" : ""}
                  color="primary"
                >
                  {rowData?.read_at ? (
                    <i class="far fa-envelope-open"></i>
                  ) : (
                    <i class="far fa-envelope"></i>
                  )}{" "}
                  Open
                </button>
              </div>,
              td
            ),
        },
      ],
      language: {
        search: "",
        searchPlaceholder: "Search",
        emptyTable: "No Record Found",
        info:
          "Showing " + from + " to " + to + " of " + totalRecords + " entries",
      },

      drawCallback: function () {
        renderPagination();
      },
    });

    table.page.len(paginationLength).draw();
    $("#UserNotificationListTable").on(
      "length.dt",
      function (e, settings, len) {
        handlePaginationLengthChange(len);
      }
    );

    $("#global-search").on("keyup", function () {
      dispatch(setSearch(this.value));
      table.search(this.value).draw();
    });

    $("#page-size").on("change", function () {
      const newSize = $(this).val();
      dispatch(setPageSize(parseInt(newSize)));
      table.page.len(parseInt(newSize)).draw();
    });

    return () => {
      $("#UserNotificationListTable").off("length.dt");
      table.destroy();
    };
  }, [notificationList, page, page_size, total_pages]);

  return (
    <UserLayout>
      <Breadcrumb items={breadcrumbItems} />
      <div className="overview-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="configure-head mt-0 mb-4">
                <div className="same-heading-icon">
                  <h4>Notifications List</h4>
                </div>
              </div>
              <div className="date-section">
                <div className="d-flex flex-wrap gap-3">
                  <div className="select-field">
                    <select
                      id="page-size"
                      defaultValue={page_size}
                      onChange={(e) => {
                        handlePaginationLengthChange(e.target.value);
                      }}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="search-field">
                    <div className="input-group">
                      <input
                        type="text"
                        id="global-search"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-success" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white-box">
                <div className="common-table m-0">
                  <table
                    className="table dt-responsive table-hover nowrap table-striped list-table"
                    style={{ width: "100%" }}
                    id="UserNotificationListTable"
                  ></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showViewNotificationModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <ViewNotificationModal
            notification={selectedNotification}
            closeViewNotificationModal={closeViewNotificationModal}
          />
        </div>
      )}

      <Spinner
        isLoading={
          isFetching ||
          isLoading ||
          isNotificationFetching ||
          isNotificationReading
        }
      />
    </UserLayout>
  );
};

export default UserNotificationList;
