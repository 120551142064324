import React, { useState, useEffect } from "react";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import InputField from "../../../components/elements/inputs/InputField";
import { Formik } from "formik";
import Select, { components } from "react-select";
import { usePutProfileUpdateMutation } from "../../../services/apiService";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../../config/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { REVERSE_ROLES } from "../../../utils/commonFunction";
import { BasicDetailSchema } from "../../../utils/validationSchema/validation";

const MySwal = withReactContent(Swal);
const countryCode = require("../../../utils/data/CountryCodes.json");

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    minHeight: "auto",
    minWidth: "39px",
    width: "40px",
    padding: "0px",

    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    minHeight: "30px",

    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const BasicDetails = () => {
  const [org_logo, setOrgLogo] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarError, setAvatarError] = useState("");
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    org_id: "",
    f_name: "",
    l_name: "",
    m_name: "",
    org_name: "",
    user_email: "",
    org_email: "",
    org_url: "",
    org_mobile: "",
    org_address: "",
    org_description: "",
    country_code: "",
    org_logo: null,
    c_code: "",
    mobile: "",
  });

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const userData = token && decodeToken(token);
  const role = userData?.user && REVERSE_ROLES[userData?.user?.role];

  const [
    putProfileUpdate,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePutProfileUpdateMutation();

  useEffect(() => {
    const encryptedOrg = localStorage.getItem("org_data");

    if (encryptedOrg) {
      try {
        const decryptedOrg = CryptoJS.AES.decrypt(
          encryptedOrg,
          process.env.REACT_APP_CRYPTOJS_SECRETKEY,
          {
            iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString(CryptoJS.enc.Utf8);

        const orgData = JSON.parse(decryptedOrg);

        setInitialValues((prevValues) => ({
          f_name: orgData?.f_name,
          l_name: orgData?.l_name,
          m_name: orgData?.m_name,
          c_code:
            countryCode?.find(
              (el) => el.label === orgData?.mobile?.split("-")[0]
            )?.label || "",
          mobile: orgData?.mobile?.split("-")[1] || "",
          org_name: orgData?.master_org.org_name || "",
          org_email: orgData?.master_org.org_email || "",
          org_url: orgData?.master_org.org_url || "",
          country_code:
            countryCode?.find(
              (el) => el.label === orgData?.master_org?.org_mobile.split("-")[0]
            )?.label || "",

          org_mobile: orgData?.master_org?.org_mobile?.split("-")[1] || "",
          org_address: orgData?.master_org.org_address || "",
          org_description: orgData?.master_org.org_description || "",
          org_logo: orgData?.master_org?.org_logo || "",
          org_id: orgData?.master_org?.org_id,
        }));
      } catch (error) {
        console.error("Error decrypting org_data:", error);
      }
    }
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1.5 * 1024 * 1024) {
      // Validate file format here
      if (file.type === "image/png") {
        setOrgLogo(file);
        setAvatarPreview(URL.createObjectURL(file));
        setAvatarError("");
      } else {
        setAvatarError("File must be in PNG format.");
      }
    } else {
      setAvatarError("File size must be less than 1.5 MB");
    }
  };

  const onSubmit = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("org_name", values.org_name);
    formData.append(
      "org_mobile",
      values?.org_mobile ? values?.country_code + "-" + values?.org_mobile : ""
    );
    formData.append(
      "mobile",
      values?.mobile ? values?.c_code + "-" + values?.mobile : ""
    );
    formData.append("org_url", values.org_url);
    formData.append("org_address", values.org_address);
    formData.append("org_description", values.org_description);
    formData.append("f_name", values.f_name);
    formData.append("m_name", values.m_name);
    formData.append("l_name", values.l_name);
    formData.append("org_email", values.org_email);
    formData.append("org_id", values.org_id);
    formData.append("user_id", userData?.user?.id);

    if (org_logo) {
      formData.append("org_logo", org_logo);
    }

    putProfileUpdate({ query: "", payload: formData }).then((res) => {
      if (res?.data?.user_data) {
        const encrypteOrg = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.user_data),
          REACT_APP_CRYPTOJS_SECRETKEY,
          { iv: REACT_APP_CRYPTOJS_VECTOR }
        ).toString();

        localStorage.setItem("org_data", encrypteOrg);
        MySwal.fire({
          title: "Success",
          text: "Profile Updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        navigate(`/${role?.toLowerCase()}/home`, { replace: true });
      }
    });
  };

  return (
    <div className="">
      <div className="row align-items-start">
        <div className="col-lg-9 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                if (!avatarError) {
                  onSubmit({ ...values, org_logo }, resetForm);
                } else {
                  toast.error(avatarError);
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={BasicDetailSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h4 className="login-heading mb-3">User Information</h4>
                  <div className="row mb-0">
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className=" input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="f_name"
                              label={"First Name"}
                              value={values.f_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.f_name && touched?.f_name ? (
                            <div className="error-message">
                              {errors?.f_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={"did-floating-label"}
                              type="text"
                              name="m_name"
                              label={"Middle Name"}
                              value={values?.m_name || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.m_name && touched?.m_name ? (
                            <div className="error-message">
                              {errors?.m_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className=" input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="l_name"
                              label={"Last Name"}
                              value={values.l_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.l_name && touched?.l_name ? (
                            <div className="error-message">
                              {errors?.l_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group  d-flex align-items-center flex-nowrap">
                            <span
                              className="input-group-text p-0"
                              style={{ width: "auto" }}
                              id="basic-addon1"
                            >
                              <Select
                                onChange={(e) =>
                                  setFieldValue("c_code", e.label)
                                }
                                placeholder={""}
                                defaultValue={{
                                  label: "+1",
                                  value: "CA",
                                }}
                                readOnly={true}
                                styles={colourStyles}
                                isSearchable={true}
                                options={countryCode}
                                value={countryCode.find(
                                  (option) => option.label === values?.c_code
                                )}
                                components={{
                                  Control,
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="mobile"
                              label={"User Contact"}
                              value={
                                values?.mobile
                                  ?.replace(/[^\d]/g, "")
                                  .replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    "$1-$2-$3"
                                  ) || ""
                              }
                              maxLength={12}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {(errors.mobile && touched.mobile) ||
                          (errors?.c_code && touched.c_code) ? (
                            <div className="error-message">
                              {errors.mobile || errors?.c_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="login-heading mb-3">Business Information</h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-envelope" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="email"
                            disabled={true}
                            name="org_email"
                            label={"Company Email"}
                            value={values.org_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.org_email && touched?.org_email ? (
                          <div className="error-message">
                            {errors?.org_email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3">
                        <div className="input-group  d-flex align-items-center flex-nowrap">
                          <span
                            className="input-group-text p-0"
                            style={{ width: "auto" }}
                            id="basic-addon1"
                          >
                            <Select
                              onChange={(e) =>
                                setFieldValue("country_code", e.label)
                              }
                              placeholder={""}
                              defaultValue={{
                                label: "+1",
                                value: "CA",
                              }}
                              readOnly={true}
                              styles={colourStyles}
                              isSearchable={true}
                              options={countryCode}
                              value={countryCode.find(
                                (option) =>
                                  option.label === values?.country_code
                              )}
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="org_mobile"
                            label={"Contact Number"}
                            value={
                              values?.org_mobile
                                ?.replace(/[^\d]/g, "")
                                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ||
                              ""
                            }
                            maxLength={12}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {(errors.org_mobile && touched.org_mobile) ||
                        (errors?.country_code && touched.country_code) ? (
                          <div className="error-message">
                            {errors.org_mobile || errors?.country_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-building" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="org_name"
                            label={"Company Name"}
                            value={values.org_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.org_name && touched.org_name ? (
                          <div className="error-message">{errors.org_name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-link" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="org_url"
                            label={"Organization's website"}
                            value={values.org_url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.org_url && touched.org_url ? (
                          <div className="error-message">{errors.org_url}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          type="text"
                          name="org_address"
                          label={"Organization Address"}
                          value={values.org_address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <i className="fa fa-info-circle" aria-hidden="true" />{" "}
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          type="text"
                          name="org_description"
                          label={"Organization Description"}
                          value={values.org_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <i className="fa fa-image" aria-hidden="true" />{" "}
                        </span>
                        <input
                          type="file"
                          accept=".png"
                          onChange={handleAvatarChange}
                          className="form-control"
                        />
                      </div>
                      {(avatarPreview || values?.org_logo) && (
                        <div className="mb-3">
                          <img
                            src={
                              avatarPreview ||
                              process.env.REACT_APP_FILE_URL + values?.org_logo
                            }
                            alt="Avatar Preview"
                            style={{ maxHeight: "100px" }}
                          />
                        </div>
                      )}
                      {avatarError ? (
                        <div className="error-message">{avatarError}</div>
                      ) : (
                        <div
                          style={{
                            color: "#ff5f15",
                            marginTop: "0px",
                            fontWeight: "normal",
                            fontSize: "12px",
                          }}
                        >
                          File size should not exceed 1.5mb.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex pb-1">
                    <span>
                      <label className="label check-terms">
                        {" "}
                        <input
                          type="checkbox"
                          required
                          style={{ marginTop: "0px" }}
                          className="form-check-input"
                          id="remember_me"
                          name="remember_me"
                          value="remember_me"
                        />{" "}
                        I agree to Data-Fusion
                        <a
                          href="https://localhost:3000/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          T&C
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://localhost:3000/privacy-policy"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </span>
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </div>
  );
};

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export default BasicDetails;
