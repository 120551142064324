import React, { useEffect } from "react";
import UserLayout from "../../components/User/UserLayout";
import BasicDetails from "../../components/User/ProfileTabComponents/BasicDetails";
import CloudDetails from "../../components/User/ProfileTabComponents/CloudDetails";
import { useLocation } from "react-router-dom";
import EmailDetails from "../../components/User/ProfileTabComponents/EmailDetails";
import SmsDetails from "../../components/User/ProfileTabComponents/SmsDetails";
import NotificationDetails from "../../components/User/ProfileTabComponents/NotificationDetails";

const UserProfile = () => {
  const location = useLocation();
  useEffect(() => {
    // Check if the state has the selectTab property set to one of the tab names
    const tabNames = ["basic", "cloud", "email", "sms", "notification"];
    const selectedTab = location.state?.selectTab;

    if (tabNames.includes(selectedTab)) {
      const tabButton = document.querySelector(
        `button[data-bs-target="#${selectedTab}"]`
      );
      if (tabButton) {
        // Trigger a click on the selected tab button
        tabButton.click();
      }
    }
  }, [location.state]);

  useEffect(() => {
    const handleTabChange = (event) => {
      const target = event.target;
      const tabNames = ["basic", "cloud", "email", "sms", "notification"];

      if (
        target &&
        tabNames.includes(target.getAttribute("data-bs-target").substring(1))
      ) {
        // Trigger an event to call API when a tab is clicked
        const tabName = target.getAttribute("data-bs-target").substring(1);
        const customEvent = new CustomEvent(`${tabName}TabSelected`);
        window.dispatchEvent(customEvent);
      }
    };

    const tabElements = document.querySelectorAll(
      'button[data-bs-toggle="tab"]'
    );
    tabElements.forEach((tabElement) => {
      tabElement.addEventListener("click", handleTabChange);
    });

    return () => {
      tabElements.forEach((tabElement) => {
        tabElement.removeEventListener("click", handleTabChange);
      });
    };
  }, []);

  return (
    <>
      <UserLayout>
        <div className="overview-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs gap-2" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Basic Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="cloud-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#cloud"
                      type="button"
                      role="tab"
                      aria-controls="cloud"
                      aria-selected="false"
                    >
                      Cloud Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="email-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#email"
                      type="button"
                      role="tab"
                      aria-controls="email"
                      aria-selected="false"
                    >
                      Email Settings
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="sms-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#sms"
                      type="button"
                      role="tab"
                      aria-controls="sms"
                      aria-selected="false"
                    >
                      SMS Settings
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="notification-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#notification"
                      type="button"
                      role="tab"
                      aria-controls="notification"
                      aria-selected="false"
                    >
                      Notification Settings
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <BasicDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="cloud"
                    role="tabpanel"
                    aria-labelledby="cloud-tab"
                  >
                    <CloudDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="email"
                    role="tabpanel"
                    aria-labelledby="email-tab"
                  >
                    <EmailDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="sms"
                    role="tabpanel"
                    aria-labelledby="sms-tab"
                  >
                    <SmsDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="notification"
                    role="tabpanel"
                    aria-labelledby="sms-tab"
                  >
                    <NotificationDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UserLayout>
    </>
  );
};

export default UserProfile;
