import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import { usePostUserActivateMutation } from "../../services/apiService";

const ActivateUser = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const [
    postUserActivate,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePostUserActivateMutation();

  const [progress, setProgress] = useState(0); // Start at 0%
  const [activationStarted, setActivationStarted] = useState(false);
  const [message, setMessage] = useState(
    "Please wait while we activate your account..."
  );
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    const activateUser = async () => {
      const userToken = urlParams.get("token");

      if (userToken === null || userToken === undefined) {
        toast.error("This link is invalid");
        navigate("/", { replace: true });
      }

      if (userToken !== null && userToken !== undefined) {
        const encryptedToken = CryptoJS.AES.encrypt(
          userToken,
          REACT_APP_CRYPTOJS_SECRETKEY,
          { iv: REACT_APP_CRYPTOJS_VECTOR }
        ).toString();

        localStorage.setItem("accessToken", encryptedToken);

        try {
          const res = await postUserActivate();

          if (res?.data?.message) {
            // Success case
            setProgress(100);
            setMessage(
              "Thank you for registering. Your account activation is successful. You will be redirected to the login screen shortly."
            );

            // Redirect after a delay (e.g., 3 seconds)
            setTimeout(() => {
              localStorage.removeItem("accessToken");
              navigate("/login");
            }, 5000);
          } else {
            // Error case
            setErrorOccurred(true);
            setMessage("Sorry, something went wrong. Please try again.");
            setProgress(100); // To stop progress animation immediately
            setTimeout(() => {
              navigate("/login");
            }, 5000);
          }
        } catch (err) {
          console.error("Failed to activate user:", err);
          setErrorOccurred(true);
          setMessage("Sorry, something went wrong. Please try again.");
          setProgress(100); // To stop progress animation immediately
          setTimeout(() => {
            navigate("/login");
          }, 5000);
        }
      }
    };

    if (!activationStarted) {
      setActivationStarted(true);
      activateUser();
    }
  }, [activationStarted, navigate, postUserActivate, urlParams]);

  return (
    <>
      <div className="thankyou-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9">
              <div className="thanks-inner">
                {message?.includes("Sorry") ? (
                  <h2 className="mr-dafoe-regular" style={{ color: "red" }}>
                    Sorry! Something went wrong.
                  </h2>
                ) : (
                  <h2 className="mr-dafoe-regular">Thank You!</h2>
                )}
                <p style={{ color: "inherit" }}>{message}</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${progress}%`,
                      backgroundColor: errorOccurred ? "red" : "",
                    }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Spinner isLoading={isFetching || isLoading} /> */}
    </>
  );
};

export default ActivateUser;
