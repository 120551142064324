import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import Select, { components } from "react-select";
import InputField from "../../../components/elements/inputs/InputField";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  useGetCloudProvidersQuery,
  useGetCloudStorageDetailsQuery,
  usePostCloudDetailsMutation,
  usePostTestCloudConnectionMutation,
} from "../../../services/apiService";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../../config/config";
import { REVERSE_ROLES } from "../../../utils/commonFunction";
import Spinner from "../../elements/Spinner/Spinner";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { getValidationSchema } from "../../../utils/validationSchema/validation";
const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
    "&:focus": {
      // border: "none",
    },
    "&:active": {
      // border: "none",
    },
    "&:hover": {
      // border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "100px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const CloudDetails = () => {
  const [cloudProvider, setCloudProvider] = useState("");
  const { data: cloudOptions } = useGetCloudProvidersQuery();
  const [initialValues, setInitialValues] = useState({
    storage_provider: "",
    account_name: "",
    account_key: "",
    container_name: "",
    connection_string: "",
    bucket_name: "",
    service_account_key: "",
    access_key_id: "",
    secret_access_key: "",
    region: "",
    api_endpoint: "",
  });
  const navigate = useNavigate();
  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const data = token && decodeToken(token);
  const role = data?.user && REVERSE_ROLES[data?.user?.role];

  const {
    data: cloudStorageDetails,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetCloudStorageDetailsQuery();

  const [
    postTestCloudConnection,
    { isFetching: isTestFetching, isLoading: istestLoading },
  ] = usePostTestCloudConnectionMutation();

  const handleTestConnection = () => {
    const payload = {
      csa_id: cloudStorageDetails?.cloud_data?.csa_id,
    };
    postTestCloudConnection({ query: "", payload: payload }).then((res) => {
      if (res?.data) {
        MySwal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    });
  };

  useEffect(() => {
    if (
      cloudStorageDetails?.cloud_data &&
      cloudOptions?.cloud_storage_providers
    ) {
      const selectedProvider = cloudOptions.cloud_storage_providers.find(
        (option) =>
          option.id ===
          parseInt(cloudStorageDetails?.cloud_data?.storage_provider)
      );

      setInitialValues({
        storage_provider: selectedProvider || "",
        account_name: cloudStorageDetails?.cloud_data?.account_name || "",
        account_key: cloudStorageDetails?.cloud_data?.account_key || "",
        container_name: cloudStorageDetails?.cloud_data?.container_name || "",
        connection_string:
          cloudStorageDetails?.cloud_data?.connection_string || "",
        bucket_name: cloudStorageDetails?.cloud_data?.bucket_name || "",
        service_account_key:
          cloudStorageDetails?.cloud_data?.service_account_key || "",
        access_key_id: cloudStorageDetails?.cloud_data?.access_key_id || "",
        secret_access_key:
          cloudStorageDetails?.cloud_data?.secret_access_key || "",
        region: cloudStorageDetails?.cloud_data?.region || "",
        api_endpoint: cloudStorageDetails?.cloud_data?.api_endpoint || "",
      });

      setCloudProvider(
        parseInt(cloudStorageDetails?.cloud_data?.storage_provider)
      );
    }
  }, [cloudStorageDetails, cloudOptions]);

  useEffect(() => {
    const handleCloudTabSelected = () => {
      refetch();
    };

    window.addEventListener("cloudTabSelected", handleCloudTabSelected);

    return () => {
      window.removeEventListener("cloudTabSelected", handleCloudTabSelected);
    };
  }, [refetch]);

  const [
    postCloudDetails,
    {
      isLoading: isSubmitting,
      isSuccess,
      isError: isSubmitError,
      error: submitError,
    },
  ] = usePostCloudDetailsMutation();

  const renderCloudFields = (
    cloudProvider,
    values,
    handleChange,
    handleBlur,
    errors,
    touched
  ) => {
    switch (cloudProvider) {
      case 1:
        return (
          <>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-cloud" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="account_name"
                  label={"Account Name"}
                  value={values.account_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.account_name && touched.account_name ? (
                <div className="error-message">{errors.account_name}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-key" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="account_key"
                  label={"Account Key"}
                  value={values.account_key}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.account_key && touched.account_key ? (
                <div className="error-message">{errors.account_key}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-folder" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="container_name"
                  label={"Container Name"}
                  value={values.container_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.container_name && touched.container_name ? (
                <div className="error-message">{errors.container_name}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-link" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="connection_string"
                  label={"Connection String"}
                  value={values.connection_string}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.connection_string && touched.connection_string ? (
                <div className="error-message">{errors.connection_string}</div>
              ) : null}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="col-lg-12 col-md-12 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-box-open" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="bucket_name"
                  label={"Bucket Name"}
                  value={values.bucket_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.bucket_name && touched.bucket_name ? (
                <div className="error-message">{errors.bucket_name}</div>
              ) : null}
            </div>
            <div className="col-lg-12 col-md-12 mb-3">
              <div className="input-group">
                {/* <span className="input-group-text">
                  <i className="fa fa-key" aria-hidden="true" />
                </span> */}
                <textarea
                  className="form-control did-floating-input"
                  name="service_account_key"
                  value={values.service_account_key}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows="4" // You can adjust the number of rows as needed
                  placeholder="Enter storage secret JSON data here..."
                />
              </div>
              {errors.service_account_key && touched.service_account_key ? (
                <div className="error-message">
                  {errors.service_account_key}
                </div>
              ) : null}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-user" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="access_key_id"
                  label={"Access Key"}
                  value={values.access_key_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.access_key_id && touched.access_key_id ? (
                <div className="error-message">{errors.access_key_id}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-key" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="secret_access_key"
                  label={"Secret Key"}
                  value={values.secret_access_key}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.secret_access_key && touched.secret_access_key ? (
                <div className="error-message">{errors.secret_access_key}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-map-marker-alt" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="region"
                  label={"Region"}
                  value={values.region}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.region && touched.region ? (
                <div className="error-message">{errors.region}</div>
              ) : null}
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-box-open" aria-hidden="true" />
                </span>
                <InputField
                  className={"did-floating-input"}
                  classNameLabel={
                    "did-floating-label did-floating-labe-astrisk"
                  }
                  type="text"
                  name="bucket_name"
                  label={"Bucket Name"}
                  value={values.bucket_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.bucket_name && touched.bucket_name ? (
                <div className="error-message">{errors.bucket_name}</div>
              ) : null}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleCloudProviderChange = (
    selectedOption,
    setFieldValue,
    resetForm
  ) => {
    setCloudProvider(selectedOption ? selectedOption.id : "");

    // Reset all fields to initial values
    resetForm({
      values: {
        storage_provider: selectedOption,
        account_name: "",
        account_key: "",
        container_name: "",
        connection_string: "",
        bucket_name: "",
        service_account_key: "",
        access_key_id: "",
        secret_access_key: "",
        region: "",
        api_endpoint: "",
      },
    });
  };

  const onSubmit = async (values) => {
    const payload = {
      storage_provider: values.storage_provider.id,
    };
    const encryptedOrg = localStorage.getItem("org_data");
    const decryptedOrg = CryptoJS.AES.decrypt(
      encryptedOrg,
      process.env.REACT_APP_CRYPTOJS_SECRETKEY,
      {
        iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
      }
    ).toString(CryptoJS.enc.Utf8);
    const orgData = decryptedOrg && JSON.parse(decryptedOrg);

    console.log("values.storage_provider.Id", values.storage_provider.id);

    switch (values.storage_provider.id) {
      case 1:
        payload.account_name = values.account_name;
        payload.account_key = values.account_key;
        payload.container_name = values.container_name;
        payload.connection_string = values.connection_string;
        break;
      case 2:
        payload.bucket_name = values.bucket_name;
        payload.service_account_key = values.service_account_key;
        break;
      case 3:
        payload.access_key_id = values.access_key_id;
        payload.secret_access_key = values.secret_access_key;
        payload.region = values.region;
        payload.api_endpoint = values.api_endpoint;
        payload.bucket_name = values.bucket_name;
        break;
      default:
        break;
    }

    postCloudDetails({
      query: "",
      payload: { ...payload, org_id: orgData?.master_org?.org_id },
    }).then((res) => {
      if (res?.data?.message) {
        MySwal.fire({
          title: "Success",
          text: "Storage details updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        refetch();
      }
    });
  };

  const validationSchema = cloudProvider && getValidationSchema(cloudProvider);
  return (
    <div className="">
      <div className="row">
        <div className="col-lg-7 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                onSubmit(values, resetForm);
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log("values", values)}
                  <h4 className="login-heading mb-3">Cloud Details</h4>
                  <div className="row mb-3">
                    <div className="col-md-12 mb-3">
                      <Select
                        getOptionLabel={(option) => `${option.provider_name}`}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={cloudOptions?.cloud_storage_providers}
                        styles={colourStyles}
                        placeholder=""
                        isRequired={true}
                        value={cloudOptions?.cloud_storage_providers?.find(
                          (option) => option.id === values?.storage_provider?.id
                        )}
                        label={"Select Cloud Provider"}
                        onChange={(option) =>
                          handleCloudProviderChange(
                            option,
                            setFieldValue,
                            resetForm
                          )
                        }
                        components={{
                          Control,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {errors.storage_provider && touched.storage_provider ? (
                        <div className="error-message">
                          {errors.storage_provider}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    {renderCloudFields(
                      cloudProvider,
                      values,
                      handleChange,
                      handleBlur,
                      errors,
                      touched
                    )}
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
          {cloudStorageDetails?.cloud_data?.csa_id && (
            <div className="right-side">
              <h5 className="login-heading mb-3">Verify Cloud Connection</h5>
              <button
                className="common-btn w-100"
                onClick={handleTestConnection}
              >
                Verify
              </button>
            </div>
          )}
        </div>
      </div>
      <Spinner
        isLoading={
          isSubmitting || isFetching || isTestFetching || istestLoading
        }
      />
    </div>
  );
};

export default CloudDetails;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
